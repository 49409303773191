.ant-select-item-option:hover {
  background-color: #f5f5f5;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
  height: 100%;
}

.headerContainer {
  position: fixed;
  width: 100%;  /* Using 100% instead of 100vw */
  height: 50px;
  box-shadow: 1px 1px 6px #9E9E9E;
  padding: 0px !important;
  z-index: 1000;
  line-height: 20px;
  left: 0;  /* Add this to ensure header is aligned properly */
  right: 0;  /* Add this to ensure header is aligned properly */
}

.headerButtonsContainer {
  text-align: right;
  margin-top: 5px;
}

.headerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.headerLogo {
  font-size: 2.25rem;
  color: white;
  height: 40px !important;
  font-weight: normal;
  text-decoration: none;
  margin-bottom: 0px !important;  /* Adjusted to 15px */
  font-family: serif;
  letter-spacing: -0.025em;
  margin-top: 50;
}

.menu-toggle {
  background: none !important;
  color: white;
}

.header-right {

}

.subHeaderText {
  font-size: 16px;
  color: black;
  margin: 5px;
  font-weight: normal;
  text-decoration: none;
}

.headerMenu {
  display: flex;
  justify-content: center;
  width: 100%;
}

.menuItem {
  font-size: 16px;
  color: #666666;
  margin: 0 15px;
  text-decoration: none;
  padding: 5px 10px;
  position: relative;
}

.menuItem:hover {
  color: #8A2BE2;
}

.menuItem.active {
  font-weight: bold;
  color: #8A2BE2;
}

.menuItem.active::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 10px;
  right: 10px;
  height: 2px;
  background-color: #8A2BE2;
}

.mainContent {
  margin-top: 0px;
  width: 100%;
  height: 100%;
  padding: 0 16px;  /* Add padding instead of allowing content to overflow */
  box-sizing: border-box;  /* This ensures padding is included in width calculation */
}

.searchContainer {
  max-width: 600px;
  margin: 0 auto 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sider {
  margin-top: 100px; /* Should match the margin-top of mainContent */
}

/* You can remove or comment out any unused classes */
