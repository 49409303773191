.actionButton, .removeButton {
  margin: 5px;
  padding: 10px !important;
  height: 24px;
  font-size: 12px;
  border-radius: 12px;
  background-color: #f0f0f0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionButton:hover, .removeButton:hover {
  background-color: #e0e0e0;
}

.removeButton {
  background-color: #ffcccb;
  color: #d32f2f;
}

.removeButton:hover {
  background-color: #ff9999;
}

.new-moment-button {
  height: auto;
  font-size: 0.75em;
  font-weight: bold;
  color: white !important;
  background: purple !important;
  border-radius: 20px;
  padding: 5px 10px;
  border: 1px solid purple;
}

.new-moment-button:hover {
  height: auto;
  font-size: 0.75em;
  font-weight: bold;
  color: white !important;
  background: gray !important;
  border-radius: 20px;
  padding: 5px 10px;
}

.new-moment-button:focus {
  height: auto;
  font-size: 0.75em;
  font-weight: bold;
  color: white !important;
  background: grey !important;
  border-radius: 20px;
  padding: 5px 10px;
}

.new-moment-button.active {
  height: auto;
  font-size: 0.75em;
  font-weight: bold;
  color: white !important;
  background: purple !important;
  border-radius: 20px;
  padding: 5px 10px;
}

.invite-friends-button {
  font-size: 12px;
  height: 30px !important;
  width: auto !important;
  padding: 6px 24px;
  background-color: #7E3FFF;
  color: white;
  border: none;
  border-radius: 20px;  /* This will make the corners round */
  cursor: pointer;
  transition: all 0.3s ease;
}

.invite-friends-button:hover {
  background-color: #e2e2e2 !important;
  color: #7E3FFF !important;
  background: yellow;
}

.invite-friends-button:focus {
  background-color: #e2e2e2 !important;
  color: #7E3FFF !important;
  box-shadow: 0 0 0 3px rgba(126, 63, 255, 0.3);
}

/* For the modal buttons */
.ant-modal-footer .ant-btn {
  border-radius: 20px;
}

.ant-modal-footer .ant-btn-primary {
  background-color: #7E3FFF;
  border-color: none;
}

.ant-modal-footer .ant-btn-primary:hover {
  background-color: #FFD700;
  border-color: #FFD700;
  color: #000000;
}

/* Cancel button style */
.ant-modal-footer .ant-btn-default {
  background-color: transparent;
  border: 1px solid #808080;
  color: #808080;
}

.ant-modal-footer .ant-btn-default:hover {
  background-color: transparent;
  border-color: #7E3FFF !important;
  color: #7E3FFF !important;
}

.ant-modal-footer .ant-btn-default:active {
  background-color: transparent;
  border-color: #000000;
  color: #000000;
}

.ant-btn {
  border: none;
  height: auto;
  padding: none;
}

.standardButton {
  background-color: #7E3FFF;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 300px;
  height: 40px;
  max-width: 320px;
  z-index: 2;
  box-shadow: 2px 5px 10px 1px #e2e2e2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0px;
  height: 40px; /* Adjust as per your needs */
  line-height: 40px; /* Keep this the same as height */
  margin-top: 5px;
}

.standardButton:hover {
  background-color: yellow;
  color: #7E3FFF !important;
  border: none;
  font-weight: 500;
  border-radius: 30px;
  font-size: 16px;
  margin-top: 5px;
  height: 40px;
  /* margin-left: 25%; */
  max-width: 320px;
}

.standardButton:focus {
  background-color: #e2e2e2;
  color: white;
  border: none;
  font-weight: 300;
  border-radius: 30px;
  font-size: 16px;
  margin-top: 5px;
  height: 40px;
  /* margin-left: 25%; */
  max-width: 320px;
}

.emptyViewAllButton {
  border: 1px solid black;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  left:
}

.standardButtonFooter {
  background-color: #7E3FFF;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 300px;
  margin-top: 5px;
  margin-bottom: 50px;
  height: 40px;
  max-width: 320px;
  z-index: 2;
  box-shadow: 2px 5px 10px 1px #e2e2e2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.standardButtonFooter:hover {
  background-color: yellow;
  color: #7E3FFF !important;
}

.standardButton:focus {
  background-color: #e2e2e2;
  color: white;
}

.searchButtonFixed {
  background-color: #7E3FFF;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 300px;
  height: 35px;
  max-width: 320px;
  z-index: 2;
  box-shadow: 2px 5px 10px 1px #e2e2e2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.standardButtonFixed {
  background-color: #7E3FFF;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 300px;
  margin-top: 5px;
  height: 35px;
  max-width: 320px;
  z-index: 2;
  box-shadow: 2px 5px 10px 1px #e2e2e2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.standardButtonFixed:hover {
  background-color: yellow;
  color: #7E3FFF;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 300px;
  margin-top: 5px;
  height: 35px;
  max-width: 320px;
  z-index: 2;
  box-shadow: 2px 5px 10px 1px #e2e2e2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.standardButtonFixed:focus {
  background-color: #e2e2e2;
  color: #7E3FFF;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  margin-top: 5px;
  height: 35px;
  max-width: 320px;
  z-index: 2;
  box-shadow: 2px 5px 10px 1px #e2e2e2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.standardButtonFixedMobile {
  background-color: #7E3FFF;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 300px;
  margin-top: 5px;
  height: 35px;
  width: 100% !important;
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.standardButtonFixedMobile:hover {
  background-color: yellow;
  color: #7E3FFF;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 300px;
  margin-top: 5px;
  height: 35px;
  width: 100% !important;
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.mobileIconFooterMenu {
  color: #7E3FFF;
}



.standardButtonFixedMobile:focus {
  background-color: #e2e2e2;
  color: #7E3FFF;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  margin-top: 5px;
  height: 35px;
  width: 100% !important;
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.standardBackButtonFixed {
  background-color: none;
  color: #7E3FFF;
  border: none;
  font-weight: 300;
  border-radius: 30px;
  font-size: 16px;
  margin-top: 5px;
  height: 35px;
  /* margin-left: 0px; */
  max-width: 320px;
  z-index: 2;
  box-shadow: 2px 5px 10px 1px #e2e2e2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.standardBackButtonFixed:hover {
  background-color: yellow !important;
  color: #7E3FFF !important;
  border: none;
  font-weight: 300;
  border-radius: 30px;
  font-size: 16px;
  margin-top: 5px;
  height: 35px;
  /* margin-left: 0px; */
  max-width: 320px;
  z-index: 2;
  box-shadow: 2px 5px 10px 1px #e2e2e2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.standardBackButtonFixed:focus {
  border: none;
  background-color: #e2e2e2;
  font-weight: 500;
  border-radius: 30px;
  font-size: 16px;
  margin-top: 5px;
  height: 35px;
  max-width: 320px;
  z-index: 2;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.standardButtonFixedBack {
  background-color: #7E3FFF;
  color: white;
  border: none;
  font-weight: 300px;
  border-radius: 30px;
  font-size: 16px;
  margin-top: 5px;
  height: 35px;
  max-width: 320px;
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 2px 5px 10px 1px #e2e2e2;
}

.standardButtonFixedBack:hover {
  background-color: yellow;
  color: #7E3FFF;
  border: none;
  font-weight: 300px;
  border-radius: 30px;
  font-size: 16px;
  margin-top: 5px;
  height: 35px;
  max-width: 320px;
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 2px 5px 10px 1px #e2e2e2;
}

.standardButtonFixedBack:focus {
  background-color: white;
  color: gray;
  border: none;
  font-weight: 300px;
  border-radius: 30px;
  font-size: 16px;
  margin-top: 5px;
  height: 35px;
  max-width: 320px;
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 2px 5px 10px 1px #e2e2e2;
}

.joinUsButton1 {
  background: none;
  background-color: none;
  color: #7E3FFF;
  border: none;
  font-size: 20px;
  box-shadow: none;
  padding-right: 5px;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.joinUsButton2 {
  background: none;
  background-color: none;
  color: #7E3FFF;
  border: none;
  font-size: 20px;
  box-shadow: none;
  padding-right: 0px;
  padding-left: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.joinUsButton:hover {
  background: none;
  background-color: none;
  color: #c0a1ff !important;
  border: none;
  font-size: 20px;
  box-shadow: none;
  padding-right: 5px;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.joinUsButton:focus {
  background: none;
  background-color: none;
  color: #7E3FFF !important;
  border: none;
  font-size: 20px;
  box-shadow: none;
  padding-right: 5px;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.enterEmailInput button {
  background-color: #7E3FFF !important;
  border-color: #7E3FFF !important;
  color: white !important;
  font-size: 16px;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-weight: normal !important;
}

.enterEmailInput button:hover{
  background-color: #ffff14 !important;
  border-color: 1px solid grey !important;
  color: #7E3FFF !important;
  font-weight: normal !important;
}

.enterEmailInput button:focus{
  background-color: #e2e2e2 !important;
  color: #7E3FFF !important;
  border-color: #7E3FFF !important;
  font-weight: normal !important;
}

.standardButtonLogIn {
  background-color: #7E3FFF;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 300px;
  margin-top: 5px;
  height: 40px;
  max-width: 320px;
  z-index: 2;
  box-shadow: 2px 5px 10px 1px #e2e2e2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}


.standardButtonLogIn:hover {
  background-color: yellow !important;
  color: #7E3FFF !important;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 300px;
  margin-top: 5px;
  height: 40px;
  max-width: 320px;
  z-index: 2;
  box-shadow: 2px 5px 10px 1px #e2e2e2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.standardButtonLogIn:focus {
  background-color: #e2e2e2;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 300px;
  margin-top: 5px;
  height: 40px;
  max-width: 320px;
  z-index: 2;
  box-shadow: 2px 5px 10px 1px #e2e2e2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.standardButtonSaveNote {
  background-color: #7E3FFF !important;
  color: white;
  border: none;
  font-weight: 300;
  border-radius: 30px;
  font-size: 16px;
  margin-top: 5px;
  height: 40px;
  margin-left: 0px;
  max-width: 100px;
}

.standardButtonSaveNote:hover {
  background-color: yellow !important;
  color: #7E3FFF !important;
  border: none;
  font-weight: 1000;
  border-radius: 30px;
  font-size: 16px;
  margin-top: 5px;
  height: 40px;
  max-width: 100px;
}

.standardButtonSaveNote:focus {
  background-color: !important #e2e2e2;
  color: !important white;
  border: none;
  font-weight: 1000;
  border-radius: 30px;
  font-size: 16px;
  margin-top: 5px;
  height: 40px;
  max-width: 100px;
}

.headerLogo {
  float: left;
  line-height: 50px;
  top: 0px;
  bottom: 0px;
  margin-left: 5px;
  margin-top: -15px;
  margin-bottom: 0px;
  padding-left: 2px;
  font-size: 20px;
  color: black;
}

.headerLogo:hover {
  color: #7E3FFF;
}

.headerButton:hover {
  border: none;
  font-weight: 300;
  border-radius: 30px;
  max-width: 320px;
  padding-bottom: 5px;
  float: right;
}

.headerButton:focus {
  border: none;
  font-weight: 300;
  border-radius: 30px;
  max-width: 320px;
  padding-bottom: 5px;
  float: right;
}

.headerButtonText {
  background-color: #7E3FFF;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  height: 35px;
  float: right;
  line-height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  top: 0px;
  margin-top: -5px;
  margin-right: 10px;
  cursor: pointer;
  box-shadow: 2px 5px 10px 1px #e2e2e2;
}

.headerButtonText:hover {
  background-color: yellow;
  color: #7E3FFF;
  border: none;
  font-weight: 1000;
  border-radius: 30px;
  font-size: 16px;
  height: 35px;
  float: right;
  line-height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  top: 0px;
  margin-top: -5px;
  margin-right: 10px;
  box-shadow: 2px 5px 10px 1px #e2e2e2;
}

.signInButtonHeader {
  background-color: white;
  color: #7E3FFF;
  border: solid 1px #7E3FFF;
  font-weight: 300;
  border-radius: 30px;
  font-size: 16px;
  width: 100px;
  margin-top: 5px;
  height: 40px;
  margin-left: 10px;
  max-width: 320px;
}

.signInButtonHeader:hover {
  background-color: yellow;
  color: #7E3FFF !important;
  border: solid 1px #7E3FFF !important;
  font-weight: 300;
  border-radius: 30px;
  font-size: 16px;
  width: 100px;
  margin-top: 5px;
  height: 40px;
  margin-left: 10px;
  max-width: 320px;
  }

.signInButtonHeader:focus {
  background-color: #e2e2e2;
  color: white;
  border: solid 1px #7E3FFF;
  font-weight: 300;
  border-radius: 30px;
  font-size: 16px;
  width: 100px;
  margin-top: 5px;
  height: 40px;
  margin-left: 10px;
  max-width: 320px;
}

.signInButtonBody {
  background-color: white;
  color: #7E3FFF;
  border: solid 1px #7E3FFF;
  font-weight: 300;
  border-radius: 30px;
  font-size: 16px;
  width: 100px;
  margin-top: 5px;
  height: 40px;
  max-width: 320px;
  margin-left: none;
}

.signInButtonBody:hover {
  background-color: yellow !important;
  color: #7E3FFF !important;
  border: solid 1px #7E3FFF;
  font-weight: 300;
  border-radius: 30px;
  font-size: 16px;
  width: 100px;
  margin-top: 5px;
  height: 40px;
  max-width: 320px;
  margin-left: none;
  }

.signInButtonBody:focus {
  background-color: #e2e2e2  !important;
  color: #7E3FFF !important;
  border: solid 1px #7E3FFF;
  font-weight: 300;
  border-radius: 30px;
  font-size: 16px;
  width: 100px;
  margin-top: 5px;
  height: 40px;
  max-width: 320px;
  margin-left: none;
}

.forgotPasswordButton {
  background-color: none;
  color: grey;
  background: none;
  border: none;
  font-weight: 500;
  border-radius: 30px;
  font-size: 14px;
  height: 40px;
  margin-left: none;
  max-width: 320px;
  -webkit-box-shadow: none;
}

.forgotPasswordButton:hover {
  background-color: none;
  color: #7E3FFF;
  background: none;
  border: none;
  font-weight: 500;
  border-radius: 30px;
  font-size: 14px;
  height: 40px;
  margin-left: none;
  max-width: 320px;
  -webkit-box-shadow: none;
  }

.forgotPasswordButton:focus {
  background-color: none;
  color: grey;
  border: none;
  background: none;
  font-weight: 500;
  border-radius: 30px;
  font-size: 14px;
  height: 40px;
  margin-left: none;
  max-width: 320px;
  -webkit-box-shadow: none;
}

.newListButton {
  background-color: #7E3FFF;
  color: white !important;
  border: none;
  font-weight: 300;
  border-radius: 30px;
  font-size: 16px;
  margin-top: 10px;
  margin-left: 15%;
  margin-right: 15%;
  width: auto;
  height: auto;
}

.newListButton:hover {
  background-color: yellow;
  color: #7E3FFF !important;
}

.newListButton:focus {
  background-color: #e2e2e2;
  color: white !important;

}

.ant-btn-default {
  box-shadow: none;
}

.productCardButton {
  background-color: none;
  border: none;
  width: 100%;
  margin: 0px auto;
  width: 225px;
  height: 225px;
}

.noBackgroundButtonGrey {
  background-color: white;
  color: grey !important;
  border: none;
  font-weight: 500;
  border-radius: 30px;
  font-size: 12px;
  width: auto;
  margin-top: 5px;
  height: 35px;
  max-width: 320px;
}

.noBackgroundButtonGrey:hover {
  background-color: white;
  color: grey !important;
  border: none;
  font-weight: 700;
  border-radius: 30px;
  font-size: 12px;
  width: auto;
  margin-top: 5px;
  height: 35px;
  max-width: 320px;
}

.noBackgroundButtonGrey:focus {
  background-color: white;
  color: grey;
  border: none;
  font-weight: 500;
  border-radius: 30px;
  font-size: 12px;
  width: auto;
  margin-top: 5px;
  height: 35px;
  max-width: 320px;
}

.noBackgroundButton {
  background-color: white;
  color: grey !important;
}

.noBackgroundButton:hover {
  background-color: white;
  color: #511ac7 !important;
}

.noBackgroundButton:focus {
  background-color: white;
  color: #7E3FFF;
}

.noBackgroundButton {
  background-color: white;
  color: grey !important;
}

.noBackgroundButton:hover {
  background-color: white;
  color: #511ac7 !important;
}

.noBackgroundButton:focus {
  background-color: white;
  color: #7E3FFF;
}

.inviteFriends {
  background-color: #7E3FFF;
  color: white;
  border: none;
  border-radius: 30px;
  width: auto;
  box-shadow: none;
  font-size: 16px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.inviteFriends:hover {
  background-color: yellow;
  color: #7E3FFF !important;
}

.inviteFriends:focus {
  background-color: #e2e2e2;
  color: #7E3FFF !important;
}

.addToListButton {
  background-color: #7E3FFF;
  color: white;
  border: none;
  border-radius: 30px;
  width: auto;
  box-shadow: none;
  margin-right: 2px;
}

.addToListButton:hover {
  background-color: yellow;
  color: #7E3FFF !important;
  border: none;
}

.addToListButton:focus {
  background-color: #e2e2e2;
  color: #7E3FFF !important;
  border: none;
}

.exploreButton {
  background-color: white;
  color: #7E3FFF;
  border: 1px solid #7E3FFF;
  border-radius: 30px;
  width: auto;
  box-shadow: none;
  font-size: 8px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 2px;

}

.exploreButton:hover {
  background-color: yellow;
  color: #7E3FFF !important;
  border: 1px solid #7E3FFF;
}

.exploreButton:focus {
  background-color: #e2e2e2;
  color: #7E3FFF !important;
  border: 1px solid #7E3FFF;
}

.RemoveFromListButton {
  background: none;
  color: red !important;
  border: none;
}

.RemoveFromListButton:hover {
  font-weight: bolder;
}

.RemoveFromListButton:focus {
  font-weight: normal;
  color: #e2e2e2;
}

.backToCategories {
  border: none;
  box-shadow: none;
  z-index: 1;
  position: fixed;
  background: none;
  padding-top: 0px;
  left: 0px;
  margin-top: 7px;
  color: red;
}

.addToListIcon .removeFromListIcon {
  box-shadow: none;
  padding: none;
  background: none;
  background-color: none;
  border: none;
  margin-top: 2px;
}

.addToListIcon {
  color: #008000 !important;
  font-size: 18px !important;
}

.addToListIcon:hover {
  color: #008000 !important;
  font-size: 18px !important;
}

.addToListIcon:focus {
  color: #008000 !important;
  font-size: 18px !important;
}

.removeFromListIcon {
  color: #FF0000 !important;
  font-size: 18px !important;
}

.removeFromListIcon:hover {
  color: #FF0000 !important;
  font-size: 18px !important;
}

.removeFromListIcon:focus {
  color: #FF0000 !important;
  font-size: 18px !important;
}

.productsIcon {
  color: grey;
  font-size: 18px !important;
}

.productsIcon:hover {
  color: black;
  font-size: 18px !important;
}

.productsIcon:focus {
  color: grey;
  font-size: 18px !important;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {


  .searchButtonFixed {

  }
  .standardButtonFixed {

  }
  .standardButtonFixedBack {
  }

  .addToListButton {
    margin-top: 5px;
    border: none;
    font-weight: 300;
    border-radius: 30px;
    font-size: 8px;
    width: auto;
    margin-top: 5px;
    height: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .noBackgroundButton {
    background: none;
    border: none;
    font-weight: 500;
    font-size: 8px;
    width: auto;
    margin-top: 5px;
    height: 25px;
    box-shadow: none;
    padding-left: none;
    padding-right: none;
  }

  .noBackgroundButton:hover {
    background: none;
  }

  .noBackgroundButton:focus {
    background: none;
  }

  .RemoveFromListButton {
    background: none;
    border: none;
    font-weight: 500;
    border-radius: 30px;
    font-size: 8px;
    width: auto;
    margin-top: 5px;
    height: 25px;
    max-width: 320px;
    box-shadow: none;
  }

  .RemoveFromListButton:hover {
    background: none;
  }

  .RemoveFromListButton:focus {
    background: none;
  }

}

@media only screen and (min-width: 600px) {
  .standardButtonFixed {
    top: 95px;
    right: 120px;
    position: fixed;
  }

  .standardButtonFixedBack {
    top: 100px;
    right: 25%;
    position: fixed;
  }

  .addToListButton {
    margin-top: 5px;
    padding-right: 10px;
    padding-left: 10px;
    border: none;
    font-weight: 700;
    border-radius: 30px;
    font-size: 12px;
    width: auto;
    margin-top: 5px;
    height: 35px;
    max-width: 320px;
  }

  .noBackgroundButton {
    background: none;
    border: none;
    font-weight: 500;
    border-radius: 30px;
    font-size: 10px;
    width: auto;
    margin-top: 5px;
    height: 35px;
    max-width: 320px;
    box-shadow: none;
  }

  .noBackgroundButton:hover {
    background: none;
  }

  .noBackgroundButton:focus {
    background: none;
  }

  .RemoveFromListButton {
    background: none;
    border: none;
    font-weight: 500;
    border-radius: 30px;
    font-size: 12px;
    width: auto;
    margin-top: 5px;
    height: 35px;
    max-width: 320px;
    box-shadow: none;
  }

  .RemoveFromListButton:hover {
    background: none;
  }

  .RemoveFromListButton:focus {
    background: none;
  }

  .standardBackButtonFixed {
    top: 0px;
  }

}
