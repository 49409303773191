.privacy-toggle .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  transition: background-color 0.3s;
  padding-left: 8px !important;
}

.privacy-toggle .ant-select-arrow {
  color: rgba(0, 0, 0, 0.5) !important;
}

.privacy-toggle .ant-select-selection-item,
.privacy-toggle .ant-select-item-option-content {
  display: flex !important;
  align-items: center !important;
}

.privacy-toggle .ant-select-selection-item .anticon,
.privacy-toggle .ant-select-item-option-content .anticon {
  margin-right: 8px !important;
}

.privacy-toggle .ant-select-item-option {
  transition: background-color 0.3s;
  padding: 8px !important;
}

.privacy-toggle .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.privacy-toggle .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 600;
}

/* Custom classes for option colors */
.privacy-toggle .option-private {
  background-color: #fefabc !important;
}

.privacy-toggle .option-friends {
  background-color: #69c0ff !important;
}

.privacy-toggle .option-community {
  background-color: #95de64 !important;
}
/* Force black text color for all switch states */
.ant-switch .ant-switch-inner {
  color: #000 !important;
}

.ant-switch:not(.ant-switch-checked) .ant-switch-inner {
  color: #000 !important;
}

.ant-switch.ant-switch-checked .ant-switch-inner {
  color: #000 !important;
}

/* Override Ant Design's CSS variables */
:root {
  --ant-switch-color: #000;
}

/* Privacy Toggle Styling */
.privacy-toggle .ant-switch {
  background-color: rgba(254, 250, 188, 0.8);
}

.privacy-toggle .ant-switch-checked {
  background-color: #69c0ff;
}

/* Sharing Toggle Styling */
.sharing-toggle .ant-switch {
  background-color: #69c0ff;
}

.sharing-toggle .ant-switch-checked {
  background-color: #95de64;
}

/* Common styles for note input container */
.note-input-container .note-input-header,
.note-input-container .sharing-toggle-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  flex-wrap: nowrap;
}

.note-input-container .note-input-header > *,
.note-input-container .sharing-toggle-container > * {
  margin-right: 4px;
  white-space: nowrap;
}

/* Toggle styles */
.privacy-toggle,
.sharing-toggle {
  display: inline-flex;
  align-items: center;
  margin: 0 2px;
}

.ant-switch {
  min-width: 80px;
  margin-right: 0;
  cursor: pointer;
}

.ant-switch-inner {
  font-size: 12px;
  color: #000 !important; /* Set text color to black for all switches */
}

/* Privacy Toggle Styling */
.privacy-toggle .ant-switch {
  background-color: rgba(254, 250, 188, 0.8);
}

.privacy-toggle .ant-switch-checked {
  background-color: #69c0ff;
}

/* Sharing Toggle Styling */
.sharing-toggle .ant-switch {
  background-color: #69c0ff;
}

.sharing-toggle .ant-switch-checked {
  background-color: #95de64;
}

/* Hover effects */
.privacy-toggle .ant-switch:hover,
.sharing-toggle .ant-switch:hover {
  opacity: 0.8;
}

/* Additional styles */
.note-input-container .note-input-header span:last-child,
.note-input-container .sharing-toggle-container span:last-child {
  margin-left: 2px;
}

.sharing-toggle-container {
  margin-top: 5px;
}

/* Style for the input area */
.note-input-container .sticky-note-input {
  margin-top: 10px;
}

/* Sharing options styles */
.sharing-options {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 1;
}

.sharing-options.expanded {
  max-height: 40px;
  margin-top: 5px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 5px;
}

.ant-radio-group {
  display: flex;
}

.ant-radio-button-wrapper {
  display: flex;
  align-items: center;
}

.note-input-container .note-input-header .privacy-toggle::after {
  content: none;
}
