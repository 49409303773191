/* Perks Tab Styles */
.perksSection {
  padding: 10px 0;
}

.perksHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 10px;
  padding: 0 5px;
}

.perksHeader h3 {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.perkCount {
  font-size: 14px;
  color: #666;
}

.perksList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.perkItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.perkInfo {
  flex: 1;
}

.perkTitle {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.perkInfo h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.perkInfo p {
  font-size: 14px;
  color: #666;
  margin: 5px 0;
}

.perkBadge {
  background-color: #6a4c93;
  color: white;
  font-size: 11px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 10px;
  margin-right: 8px;
}

.perkExpiry {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
}

.perkStatus {
  font-size: 12px;
  margin-top: 5px;
}

.statusText {
  color: #4caf50;
  font-weight: 600;
}

.claimButton {
  background-color: #6a4c93;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
}

.claimButton:hover {
  background-color: #5a3b83;
}

.claimedBadge {
  background-color: #4caf50;
  color: white;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 15px;
}

.emptyPerksState {
  text-align: center;
  padding: 20px;
  color: #666;
}

.emptyPerksState p {
  margin: 5px 0;
}

.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f5f7fa;
    position: relative;
    padding-bottom: 60px; /* Space for bottom navigation */
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    position: sticky;
    top: 0;
    z-index: 10;
    margin-top: 50px;
  }
  
  .title {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    font-family: 'Quicksand', sans-serif;
    color: #333333;
  }
  
  .settingsButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    color: #666666;
    padding: 8px;
    border-radius: 50%;
  }
  
  .settingsButton:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .icon, .navIcon {
    width: 24px;
    height: 24px;
  }
  
  .profileContainer {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .profileHeader {
    display: flex;
    gap: 16px;
    background-color: white;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
  
  .profileAvatarContainer {
    position: relative;
  }
  
  .avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #6a4c93;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 32px;
    font-weight: 500;
    overflow: hidden;
  }
  
  .initials {
    font-family: 'Quicksand', sans-serif;
  }
  
  .avatarImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .photoUploadButton {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #6a4c93;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
    cursor: pointer;
    padding: 0;
  }
  
  .photoUploadButton .icon {
    width: 16px;
    height: 16px;
  }
  
  .profileInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .profileName {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 4px 0;
    font-family: 'Quicksand', sans-serif;
  }
  
  .profileBio {
    font-size: 14px;
    color: #666666;
    margin: 0 0 12px 0;
    line-height: 1.4;
  }
  
  .editProfileButton {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: transparent;
    border: 1px solid #6a4c93;
    color: #6a4c93;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    margin-top: auto;
    align-self: flex-start;
  }
  
  .editProfileButton .icon {
    width: 16px;
    height: 16px;
  }
  
  .babyInfoCard {
    background-color: #f0e6fa;
    border-radius: 12px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .babyInfoTitle {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 4px 0;
    font-family: 'Quicksand', sans-serif;
    color: #6a4c93;
  }
  
  .babyInfoAge {
    font-size: 16px;
    margin: 0;
    color: #6a4c93;
  }
  
  .statsContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
  }
  
  .statCard {
    background-color: white;
    border-radius: 8px;
    padding: 12px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .statValue {
    font-size: 20px;
    font-weight: 700;
    color: #6a4c93;
    margin-bottom: 4px;
  }
  
  .statLabel {
    font-size: 12px;
    color: #666666;
    text-align: center;
  }
  
  .tabsContainer {
    display: flex;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 8px;
  }
  
  .tabButton {
    flex: 1;
    padding: 12px 8px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #666666;
    position: relative;
  }
  
  .activeTab {
    color: #6a4c93;
    font-weight: 700;
  }
  
  .activeTab::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #6a4c93;
  }
  
  .tabContent {
    margin-top: 16px;
  }
  
  .listsGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;
  }
  
  @media (min-width: 768px) {
    .listsGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  .listCard {
    background-color: white;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    border-left: 4px solid;
  }
  
  .listCard:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .purpleTheme {
    border-color: #6a4c93;
  }
  
  .blueTheme {
    border-color: #4c93a0;
  }
  
  .pinkTheme {
    border-color: #934c6d;
  }
  
  .greenTheme {
    border-color: #4c936a;
  }
  
  .orangeTheme {
    border-color: #936a4c;
  }
  
  .defaultTheme {
    border-color: #666666;
  }
  
  .listTitle {
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 8px 0;
    font-family: 'Quicksand', sans-serif;
  }
  
  .listDetails {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  .listType {
    font-size: 14px;
    color: #666666;
    text-transform: capitalize;
  }
  
  .listItemCount {
    font-size: 14px;
    color: #666666;
  }
  
  .listDate {
    font-size: 12px;
    color: #999999;
  }
  
  .creatorInfo {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
  }
  
  .creatorInfo .avatar {
    width: 24px;
    height: 24px;
    font-size: 12px;
  }
  
  .creatorName {
    font-size: 14px;
    color: #666666;
  }
  
  .emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 16px;
    text-align: center;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .emptyState p {
    margin: 0 0 16px 0;
    color: #666666;
  }
  
  .createListButton, .exploreButton {
    background-color: #6a4c93;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .followingContainer {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Bottom Navigation */
  .bottomNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    background-color: white;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.05);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  
  .navItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 16px;
    color: #666666;
    font-size: 12px;
    cursor: pointer;
  }
  
  .navItem.active {
    color: #6a4c93;
    font-weight: 700;
  }
  
  /* Print styles */
  @media print {
    .header,
    .bottomNav,
    .editProfileButton,
    .photoUploadButton {
      display: none;
    }
    
    .container {
      padding-bottom: 0;
    }
  }