/* Perk Badge Styles */
.perkContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding: 10px 15px;
  background-color: #f8f5ff;
  border-radius: 8px;
}

.perkBadge {
  display: flex;
  align-items: center;
  color: #6a4c93;
  font-weight: 600;
  font-size: 15px;
}

.perkIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  stroke: #6a4c93;
}

.perkHighlight {
  margin-left: 8px;
  background-color: #6a4c93;
  color: white;
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
}

.perkClaimButton {
  background-color: #6a4c93;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.perkClaimButton:hover {
  background-color: #5a3b83;
}

/* Perk Details Section */
.perkDetailsSection {
  margin-top: 16px;
  padding: 15px;
  background-color: #f8f5ff;
  border-radius: 8px;
}

.perkTitle {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 8px 0;
  color: #6a4c93;
}

.perkDescription {
  font-size: 14px;
  line-height: 1.5;
  margin: 0 0 10px 0;
}

.perkMessage {
  font-size: 14px;
  color: #555;
  font-style: italic;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: #f5f7fa;
  min-height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 10;
  margin-top: 50px;
}

.title {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  color: #333;
}

.backButton,
.editButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.backButton:hover,
.editButton:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.backButton {
  font-size: 24px;
  margin-right: 8px;
  color: #666;
}

.editButton {
  color: #6a4c93;
  font-weight: 600;
}

.itemCard {
  background-color: white;
  border-radius: 8px;
  margin: 16px;
  padding: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
}

.itemHeader {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 16px;
}

.itemName {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin: 0;
  color: #333;
}

.priorityBadge {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 600;
  align-self: flex-start;
}

.priorityBadge[data-priority="must-have"] {
  background-color: #f0e6fa;
  color: #6a4c93;
}

.priorityBadge[data-priority="nice-to-have"] {
  background-color: #e6f5fa;
  color: #4c6e93;
}

.brandContainer {
  margin-bottom: 16px;
}

.brandLabel {
  font-weight: 600;
  color: #666;
  margin-right: 8px;
}

.brandValue {
  color: #333;
}

.detailsRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 12px;
}

.detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.detailLabel {
  font-size: 14px;
  color: #666;
}

.categoryBadge,
.ageBadge,
.priceBadge {
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  text-align: center;
  min-width: 60px;
}

.categoryBadge {
  background-color: #f5fae6;
  color: #6a934c;
}

.ageBadge {
  background-color: #fae6f0;
  color: #934c6a;
}

.priceBadge {
  background-color: #e6faf0;
  color: #4c936a;
}

/* Trial container styles - New */
.trialContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff8e1;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 20px;
}

.trialBadge {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #f57c00;
  font-weight: 600;
}

.trialIcon {
  width: 20px;
  height: 20px;
  stroke: #f57c00;
}

.trialInfoButton {
  background-color: #f57c00;
  color: white;
  border: none;
  border-radius: 16px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.trialInfoButton:hover {
  background-color: #ef6c00;
}

/* Trial Details Section - New */
.trialDetailsSection {
  background-color: #fff8e1;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  border-left: 4px solid #f57c00;
}

.trialTitle {
  font-family: 'Quicksand', sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 8px 0;
  color: #333;
  display: flex;
  align-items: center;
  gap: 8px;
}

.trialTitle::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23f57c00' stroke-width='2'%3E%3Cpath d='M22 12h-4l-3 9L9 3l-3 9H2'%3E%3C/path%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
}

.trialContent {
  color: #555;
  line-height: 1.6;
  margin: 0;
}

.descriptionContainer {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
}

.description {
  color: #333;
  line-height: 1.5;
  margin: 0;
}

.notesSection {
  background-color: #fafafa;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.notesTitle {
  font-family: 'Quicksand', sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 8px 0;
  color: #333;
}

.notesContent {
  color: #444;
  line-height: 1.6;
  margin: 0;
  white-space: pre-line;
}

.acquiredContainer {
  margin-bottom: 20px;
}

.acquiredLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  position: relative;
  padding-left: 36px;
  margin-bottom: 8px;
}

.acquiredCheckbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #eee;
  border-radius: 4px;
  transition: all 0.2s;
}

.acquiredLabel:hover .checkmark {
  background-color: #ccc;
}

.acquiredCheckbox:checked ~ .checkmark {
  background-color: #6a4c93;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.acquiredCheckbox:checked ~ .checkmark:after {
  display: block;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.acquiredText {
  font-size: 16px;
  color: #444;
  margin-left: 10px;
  padding-top: 2px;
}

.actionsContainer {
  display: flex;
  gap: 12px;
}

/* Trial Button - New */
.trialButton {
  flex: 1;
  background-color: #f57c00;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 12px 16px;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.trialButton:hover {
  background-color: #ef6c00;
}

.purchaseButton {
  flex: 1;
  background-color: #6a4c93;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 12px 16px;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.purchaseButton:hover {
  background-color: #5a3e83;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.loadingText {
  color: #666;
  font-size: 16px;
}

/* Media queries for responsiveness */
@media (min-width: 768px) {
  .itemCard {
    max-width: 600px;
    margin: 24px auto;
  }
  
  .itemHeader {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .detailsRow {
    justify-content: flex-start;
    gap: 36px;
  }
}