/* Add these new styles to your existing SharingList.module.css file */

.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f8f9fa;
  }
  
  .header {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  .backButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }
  
  .title {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
  }
  
  .content {
    flex-grow: 1;
    padding: 1rem;
  }
  
  .sectionTitle {
    font-size: 1.125rem;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .previewSection {
    margin-bottom: 1.5rem;
  }
  
  .previewCard {
    background-color: white;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .blueTheme {
    border-left: 4px solid #4A90E2;
  }
  
  .purpleTheme {
    border-left: 4px solid #9B51E0;
  }
  
  .pinkTheme {
    border-left: 4px solid #E91E63;
  }
  
  .greenTheme {
    border-left: 4px solid #27AE60;
  }
  
  .defaultTheme {
    border-left: 4px solid #6B4EFF;
  }
  
  .previewHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .previewTitle {
    margin: 0;
    font-size: 1.125rem;
    font-weight: 600;
  }
  
  .previewType {
    font-size: 0.75rem;
    background-color: #f3f4f6;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    color: #4B5563;
  }
  
  .previewDetails p {
    margin: 0.5rem 0;
  }
  
  .previewMessage {
    background-color: #f3f4f6;
    padding: 0.5rem;
    border-radius: 4px;
    margin-top: 0.5rem;
  }
  
  .previewMessage h4 {
    margin: 0 0 0.25rem;
    font-size: 0.875rem;
    font-weight: 600;
  }
  
  .previewMessage p {
    margin: 0;
    font-size: 0.875rem;
  }
  
  .previewPrivacy {
    margin-top: 0.5rem;
    border-top: 1px dashed #e2e8f0;
    padding-top: 0.5rem;
  }
  
  .previewPrivacyText {
    margin: 0;
    font-size: 0.75rem;
    color: #718096;
  }
  
  .messageSection {
    margin-bottom: 1.5rem;
  }
  
  .messageInput {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-family: inherit;
    resize: vertical;
  }
  
  .privacySection {
    margin-bottom: 1.5rem;
  }
  
  .privacyOptions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .privacyOption {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    margin-right: 0.5rem;
  }
  
  .privacyOption input:checked ~ .checkmark:after {
    content: "";
    position: absolute;
    display: block;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #6B4EFF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  .sharingSection {
    margin-bottom: 1.5rem;
  }
  
  .sharingButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  
  .shareButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    border: none;
  }
  
  .shareButton span {
    margin-left: 0.5rem;
  }
  
  .icon {
    width: 20px;
    height: 20px;
  }
  
  .whatsappButton {
    background-color: #25D366;
    color: white;
  }
  
  .whatsappButton:hover {
    background-color: #1ea952;
  }
  
  .facebookButton {
    background-color: #1877F2;
    color: white;
  }
  
  .facebookButton:hover {
    background-color: #0c60cf;
  }
  
  .emailButton {
    background-color: #EA4335;
    color: white;
  }
  
  .emailButton:hover {
    background-color: #d33426;
  }
  
  .linkButton {
    background-color: #6B4EFF;
    color: white;
  }
  
  .linkButton:hover {
    background-color: #5A3FD6;
  }
  
  /* New view button styles */
  .viewButton {
    background-color: #4A90E2;
    color: white;
    grid-column: span 2;
    margin-top: 0.5rem;
  }
  
  .viewButton:hover {
    background-color: #3A80D2;
  }
  
  .pdfSection {
    margin-bottom: 1.5rem;
  }
  
  .pdfButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.75rem;
    background-color: #f3f4f6;
    color: #4B5563;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .pdfButton:hover {
    background-color: #e5e7eb;
  }
  
  .pdfButton span {
    margin-left: 0.5rem;
  }
  
  @media (min-width: 640px) {
    .sharingButtons {
      grid-template-columns: repeat(4, 1fr);
    }
    
    .viewButton {
      grid-column: span 4;
    }
  }