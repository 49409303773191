.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f9fa;
  font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', Helvetica, Arial, sans-serif;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  color: white;
  position: sticky;
  top: 0;
  z-index: 10;
  margin-top: 50px;
}

.blueTheme {
  background-color: #4A90E2;
}

.purpleTheme {
  background-color: #9B51E0;
}

.pinkTheme {
  background-color: #E91E63;
}

.greenTheme {
  background-color: #27AE60;
}

.defaultTheme {
  background-color: #6B4EFF;
}

.backButton {
  background: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.backButton:hover {
  background: rgba(255, 255, 255, 0.3);
}

.title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  flex-grow: 1;
  text-align: center;
}

.headerActions {
  display: flex;
  gap: 0.5rem;
}

.printButton {
  background: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

.printButton:hover {
  background: rgba(255, 255, 255, 0.3);
}

.icon {
  width: 20px;
  height: 20px;
}

.creatorContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: white;
  border-bottom: 1px solid #eaeaea;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.creatorInfo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #6B4EFF;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
}

.creatorDetails {
  display: flex;
  flex-direction: column;
}

.creatorName {
  font-weight: 600;
  color: #333;
}

.sharedDate {
  font-size: 0.8rem;
  color: #777;
}

.thankButton {
  background-color: transparent;
  border: 1px solid #6B4EFF;
  color: #6B4EFF;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.thankButton:hover {
  background-color: rgba(107, 78, 255, 0.1);
}

.thanked {
  background-color: #6B4EFF;
  color: white;
  border-color: #6B4EFF;
}

.thanked:hover {
  background-color: #6B4EFF;
  opacity: 0.9;
}

.listStats {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  background-color: white;
  margin-bottom: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.statItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.statValue {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
}

.statLabel {
  font-size: 0.8rem;
  color: #777;
}

.starRating {
  display: flex;
  color: #FFB400;
  font-size: 1.2rem;
  line-height: 1;
}

.star {
  margin-right: 2px;
}

.noteContainer {
  background-color: white;
  padding: 1rem;
  margin: 0 1rem 1rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.noteTitle {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #333;
}

.noteText {
  margin: 0;
  color: #555;
  line-height: 1.4;
  font-size: 0.95rem;
}

/* Filter container styles - New */
.filterContainer {
  padding: 0 1rem 1rem;
  display: flex;
  justify-content: center;
}

.filterButton {
  padding: 0.5rem 1rem;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 1.5rem;
  font-size: 0.9rem;
  color: #555;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filterButton:hover {
  border-color: #bbb;
}

.activeFilter {
  background-color: #FFF8E1;
  border-color: #F57C00;
  color: #F57C00;
}

.sectionTitle {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0 1rem 0.5rem;
  color: #333;
}

.itemsContainer {
  flex-grow: 1;
  padding-bottom: 1rem;
}

.itemCard {
  background-color: white;
  margin: 0 1rem 1rem;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s, box-shadow 0.2s;
}

.itemCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.itemContent {
  display: flex;
  padding: 1rem;
  gap: 1rem;
  align-items: center;
}

.itemEmoji {
  font-size: 2rem;
  background-color: #f3f4f6;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.itemDetails {
  flex-grow: 1;
}

.itemName {
  margin: 0 0 0.25rem;
  font-size: 1rem;
  font-weight: 600;
  color: #333;
}

.itemBrand {
  margin: 0 0 0.5rem;
  font-size: 0.85rem;
  color: #777;
}

/* Item badges container - New */
.itemBadges {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
}

.priorityBadge {
  display: inline-block;
  font-size: 0.75rem;
  padding: 0.2rem 0.6rem;
  border-radius: 1rem;
  font-weight: 500;
}

.priorityBadge[data-priority="must-have"] {
  background-color: #FFE4E8;
  color: #E91E63;
}

.priorityBadge[data-priority="nice-to-have"] {
  background-color: #E6F5FA;
  color: #4A90E2;
}

/* Trial badge styles - New */
.trialBadge {
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
  padding: 0.2rem 0.6rem;
  border-radius: 1rem;
  background-color: #FFF8E1;
  color: #F57C00;
  gap: 0.25rem;
  cursor: pointer;
}

/* Trial icon style - New */
.trialIcon {
  width: 14px;
  height: 14px;
  stroke: #F57C00;
}

.addItemButton {
  background-color: transparent;
  border: 1px solid #6B4EFF;
  color: #6B4EFF;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  white-space: nowrap;
}

.addItemButton:hover {
  background-color: rgba(107, 78, 255, 0.1);
}

.itemAdded {
  background-color: #6B4EFF;
  color: white;
}

.itemAdded:hover {
  background-color: #5A3FD6;
}

/* Trial details container - New */
.trialDetailsContainer {
  padding: 0.75rem 1rem;
  background-color: #FFF8E1;
  border-top: 1px dashed #FFB74D;
}

.trialDetailsTitle {
  margin: 0 0 0.25rem;
  font-size: 0.85rem;
  font-weight: 600;
  color: #F57C00;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.trialDetailsText {
  margin: 0;
  font-size: 0.85rem;
  color: #555;
  line-height: 1.4;
}

.actionsContainer {
  padding: 1rem;
  position: sticky;
  bottom: 0;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
  z-index: 5;
}

.saveListButton {
  background-color: #6B4EFF;
  color: white;
  border: none;
  border-radius: 1.5rem;
  padding: 0.75rem 2rem;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.2s;
}

.saveListButton:hover {
  background-color: #5A3FD6;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.loginPrompt {
  background-color: white;
  border-radius: 12px;
  padding: 1.5rem;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.promptTitle {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
}

.promptText {
  margin-bottom: 1.5rem;
  color: #555;
  line-height: 1.4;
}

.promptActions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.cancelButton {
  background-color: transparent;
  border: 1px solid #ddd;
  color: #555;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.cancelButton:hover {
  background-color: #f5f5f5;
}

.loginButton {
  background-color: #6B4EFF;
  color: white;
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 1.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.loginButton:hover {
  background-color: #5A3FD6;
}

.signupPrompt {
  position: fixed;
  bottom: 5rem;
  left: 0;
  right: 0;
  background-color: white;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
  z-index: 4;
}

.promptInfo {
  margin: 0;
  font-size: 0.9rem;
  color: #555;
  flex-grow: 1;
}

.signupButton {
  background-color: #6B4EFF;
  color: white;
  border: none;
  padding: 0.5rem 1.25rem;
  border-radius: 1.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  white-space: nowrap;
  margin-left: 1rem;
}

.signupButton:hover {
  background-color: #5A3FD6;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loadingText {
  color: #777;
  font-size: 1rem;
}

/* Empty state styles - New */
.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  text-align: center;
  color: #777;
}

.clearFilterButton {
  margin-top: 1rem;
  background-color: #FFF8E1;
  color: #F57C00;
  border: 1px solid #F57C00;
  padding: 0.5rem 1.25rem;
  border-radius: 1.5rem;
  font-weight: 500;
  cursor: pointer;
}

/* Print styles */
@media print {
  .header, .backButton, .printButton, .thankButton, 
  .filterContainer, .addItemButton, .actionsContainer, .signupPrompt {
    display: none;
  }
  
  .container {
    background-color: white;
  }
  
  .itemCard {
    break-inside: avoid;
    box-shadow: none;
    border: 1px solid #eaeaea;
  }
  
  .noteContainer {
    box-shadow: none;
    border: 1px solid #eaeaea;
  }
}

/* Mobile responsiveness */
@media (max-width: 480px) {
  .itemContent {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .itemEmoji {
    margin-bottom: 0.5rem;
  }
  
  .addItemButton {
    align-self: stretch;
    margin-top: 0.75rem;
    text-align: center;
  }
  
  .listStats {
    padding: 0.75rem 0.5rem;
  }
  
  .statValue {
    font-size: 1.1rem;
  }
}

/* Tablet and larger devices */
@media (min-width: 768px) {
  .container {
    max-width: 800px;
    margin: 0 auto;
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
  }
  
  .header {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  
  .title {
    font-size: 1.5rem;
  }
}