/* Perks icon */
.perkIcon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  vertical-align: middle;
}

/* Personal Perks Section */
.personalPerks {
  margin: 15px 0;
  padding: 15px;
  background-color: #f8f5ff;
  border-radius: 12px;
}

.perksHeader {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 5px 0;
  color: #6a4c93;
}

.perksDescription {
  font-size: 14px;
  color: #666;
  margin: 0 0 15px 0;
}

.perksList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.perkItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.perkInfo {
  flex: 1;
}

.perkTitle {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.perkTitle h4 {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}

.perkInfo p {
  font-size: 13px;
  color: #666;
  margin: 0;
}

.perkBadge {
  background-color: #6a4c93;
  color: white;
  font-size: 11px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 10px;
  margin-right: 8px;
}

.claimButton {
  background-color: #6a4c93;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 12px;
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
}

.claimButton:hover {
  background-color: #5a3b83;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff;
  padding-bottom: 80px;
}

.header {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #eaeaea;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 10;
  margin-top: 50px;
}

.backButton, .detailViewButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backButton {
  font-size: 24px;
  margin-right: 8px;
  color: #333;
}

.detailViewButton {
  color: #6a4c93;
}

.title {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  flex-grow: 1;
  text-align: center;
}

.icon {
  width: 20px;
  height: 20px;
}

.progressContainer {
  padding: 16px;
  margin-bottom: 16px;
}

.progressText {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: center;
}

.progressBarContainer {
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
}

.progressBar {
  height: 100%;
  transition: width 0.3s ease;
}

/* Theme colors for progress bar */
.blueTheme {
  background-color: #4299e1;
}

.purpleTheme {
  background-color: #9f7aea;
}

.pinkTheme {
  background-color: #ed64a6;
}

.greenTheme {
  background-color: #48bb78;
}

.defaultTheme {
  background-color: #718096;
}

.filtersContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 16px 16px;
  gap: 8px;
  flex-wrap: wrap;
}

.filterButton {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 20px;
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  color: #4a5568;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 100px;
  text-align: center;
}

.activeFilter {
  background-color: #ebf4ff;
  border-color: #4299e1;
  color: #4299e1;
}

/* "Trial before you buy" filter button specific style */
.filterButton:nth-child(3).activeFilter {
  background-color: #fff8e1;
  border-color: #f57c00;
  color: #f57c00;
}

/* Active filters bar - New */
.activeFiltersBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background-color: #f8fafc;
  border-top: 1px solid #e2e8f0;
  border-bottom: 1px solid #e2e8f0;
  margin-bottom: 16px;
}

.activeFiltersText {
  font-size: 14px;
  color: #4a5568;
}

.clearFiltersButton {
  background: none;
  border: none;
  color: #4299e1;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.checklistContainer {
  flex-grow: 1;
  padding: 0 16px;
  overflow-y: auto;
}

.checklistItem {
  display: flex;
  align-items: center;
  padding: 16px;
  margin-bottom: 12px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  transition: all 0.2s ease;
  cursor: pointer;
}

.checkedItem {
  background-color: #f7fafc;
  border-color: #e2e8f0;
}

.checkedItem .itemName {
  text-decoration: line-through;
  color: #a0aec0;
}

.checkboxContainer {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 16px;
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: relative;
  height: 24px;
  width: 24px;
  background-color: white;
  border: 2px solid #e2e8f0;
  border-radius: 4px;
}

.checkbox:checked ~ .checkmark {
  background-color: #4299e1;
  border-color: #4299e1;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox:checked ~ .checkmark:after {
  display: block;
}

.checkboxContainer .checkmark:after {
  left: 8px;
  top: 4px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.itemDetails {
  flex-grow: 1;
}

.itemName {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

/* New style for item badges container */
.itemBadges {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.priorityBadge {
  display: inline-block;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 12px;
}

.mustHave {
  background-color: #feebee;
  color: #e53e3e;
}

.niceToHave {
  background-color: #ebf8ff;
  color: #4299e1;
}

/* Trial badge styles - New */
.trialBadge {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 12px;
  background-color: #fff8e1;
  color: #f57c00;
  gap: 4px;
}

.trialIcon {
  width: 14px;
  height: 14px;
  stroke: #f57c00;
}

.addButton {
  position: fixed;
  bottom: 24px;
  right: 24px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #6a4c93;
  color: white;
  border: none;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 20;
}

.buttonText {
  margin-left: 8px;
}

.actionsContainer {
  padding: 16px;
  display: flex;
  justify-content: center;
}

.printButton {
  padding: 10px 16px;
  background-color: white;
  border: 1px solid #4299e1;
  color: #4299e1;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  text-align: center;
  color: #718096;
}

/* Print styles - hide certain elements when printing */
@media print {
  .header,
  .filtersContainer,
  .activeFiltersBar,
  .addButton,
  .actionsContainer {
    display: none;
  }
  
  .container {
    padding: 0;
  }
  
  .checklistItem {
    break-inside: avoid;
    page-break-inside: avoid;
  }
  
  .progressContainer {
    margin-top: 20px;
  }
}