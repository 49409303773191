.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f5f7fa;
    font-family: 'Nunito', sans-serif;
  }
  
  .header {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 16px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 10;
    margin-top: 50px;
  }
  
  .backButton {
    background: none;
    border: none;
    font-size: 24px;
    color: #333;
    cursor: pointer;
    padding: 8px;
    margin-right: 8px;
  }
  
  .title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    margin: 0;
    flex: 1;
  }
  
  .settingsContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
  }
  
  .settingsSection {
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .sectionHeader {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .sectionIcon {
    width: 20px;
    height: 20px;
    color: #6a4c93;
    margin-right: 12px;
  }
  
  .sectionTitle {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
    color: #333;
  }
  
  .settingItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
  }
  
  .settingItem:last-child {
    border-bottom: none;
  }
  
  .settingInfo {
    display: flex;
    flex-direction: column;
  }
  
  .settingLabel {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    margin-bottom: 2px;
  }
  
  .settingDescription {
    font-size: 14px;
    color: #666;
  }
  
  .settingValue {
    font-size: 14px;
    color: #666;
  }
  
  .chevronIcon {
    width: 16px;
    height: 16px;
    color: #999;
  }
  
  /* Toggle Switch Styles */
  .toggle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 28px;
  }
  
  .toggle input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .3s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .3s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #6a4c93;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #6a4c93;
  }
  
  input:checked + .slider:before {
    transform: translateX(22px);
  }
  
  /* Add Button Styles */
  .addButton {
    background: none;
    border: none;
    color: #6a4c93;
    font-size: 16px;
    font-weight: 500;
    padding: 16px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .addButton:hover {
    background-color: rgba(106, 76, 147, 0.05);
  }
  
  /* Logout Button Styles */
  .logoutButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px;
    background: none;
    border: none;
    color: #e74c3c;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    gap: 8px;
  }
  
  .logoutButton:hover {
    background-color: rgba(231, 76, 60, 0.05);
  }
  
  .logoutIcon {
    width: 20px;
    height: 20px;
    color: #e74c3c;
  }
  
  /* Responsive Adjustments */
  @media (min-width: 768px) {
    .settingsContainer {
      max-width: 600px;
      margin: 0 auto;
      padding: 24px;
    }
  }