.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #ffffff;
  font-family: 'Nunito', sans-serif;
}

.header {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 10;
}

.backButton {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 8px;
  margin-right: 12px;
  color: #333333;
}

.title {
  flex: 1;
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #333333;
}

.filterCount {
  background-color: #6a4c93;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
}

.filterPanel {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

.filterSection {
  margin-bottom: 24px;
}

.sectionTitle {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  margin-bottom: 12px;
}

.toggleButtons {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.toggleButton {
  background-color: #f5f7fa;
  border: 1px solid #dddddd;
  border-radius: 25px;
  padding: 8px 16px;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  color: #666666;
  cursor: pointer;
  transition: all 0.2s ease;
}

.toggleButton.active {
  background-color: #6a4c93;
  border-color: #6a4c93;
  color: white;
}

/* Trial Before Buying specific toggle button styles - New */
.filterSection:nth-of-type(6) .toggleButton.active {
  background-color: #f57c00;
  border-color: #f57c00;
  color: white;
}

/* Filter description text - New */
.filterDescription {
  font-size: 13px;
  color: #777777;
  margin-top: 8px;
  line-height: 1.4;
  max-width: 400px;
}

/* Trial Before Buying specific description styles - New */
.filterSection:nth-of-type(6) .filterDescription {
  border-left: 2px solid #f57c00;
  padding-left: 8px;
  color: #666666;
}

.chipContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.chipButton {
  background-color: #f5f7fa;
  border: 1px solid #dddddd;
  border-radius: 25px;
  padding: 6px 12px;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  color: #666666;
  cursor: pointer;
  transition: all 0.2s ease;
}

.chipButton.active {
  background-color: #6a4c93;
  border-color: #6a4c93;
  color: white;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #333333;
}

.checkbox {
  margin-right: 8px;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  background-color: white;
  position: relative;
  cursor: pointer;
}

.checkbox:checked {
  background-color: #6a4c93;
  border-color: #6a4c93;
}

.checkbox:checked::after {
  content: '';
  position: absolute;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%) rotate(45deg);
}

.checkboxText {
  font-family: 'Nunito', sans-serif;
}

.filterActions {
  display: flex;
  padding: 16px;
  border-top: 1px solid #f0f0f0;
  background-color: #ffffff;
  position: sticky;
  bottom: 0;
  z-index: 10;
}

.clearButton {
  background: none;
  border: none;
  color: #6a4c93;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 14px;
  padding: 12px 16px;
  cursor: pointer;
  margin-right: auto;
}

.applyButton {
  background-color: #6a4c93;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 12px 24px;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.applyButton:hover {
  background-color: #5a3d83;
}

/* Responsive styles */
@media (min-width: 768px) {
  .filterPanel {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
  
  .filterSection {
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 800px;
    margin: 0 auto;
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
  }
}