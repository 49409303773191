.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f5f7fa;
  font-family: 'Nunito', sans-serif;
}

.header {
  padding: 16px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  z-index: 10;
  margin-top: 50px;
}

.title {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #333;
  text-align: center;
}

.tabsContainer {
  display: flex;
  background-color: white;
  border-bottom: 1px solid #eee;
}

.tabButton {
  flex: 1;
  padding: 12px 0;
  border: none;
  background: none;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
}

.activeTab {
  color: #6a4c93;
  border-bottom: 2px solid #6a4c93;
}

.searchContainer {
  padding: 12px 16px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 5;
}

.searchInputContainer {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 25px;
  padding: 8px 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.searchInput {
  flex-grow: 1;
  border: none;
  background: transparent;
  padding: 8px;
  font-size: 14px;
  color: #333;
  outline: none;
}

.icon {
  width: 18px;
  height: 18px;
  color: #666;
  margin-right: 8px;
}

.filtersContainer {
  padding: 0 16px 12px;
  background-color: white;
  border-bottom: 1px solid #eee;
}

.filterScrollContainer {
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 8px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.filterScrollContainer::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.filterSection {
  display: inline-block;
  margin-right: 12px;
}

.filterButton {
  display: inline-block;
  margin-right: 8px;
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background-color: white;
  font-size: 12px;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
}

.activeFilter {
  background-color: #6a4c93;
  border-color: #6a4c93;
  color: white;
}

/* Trial filter active state - New */
.filterSection:nth-child(3) .filterButton.activeFilter {
  background-color: #f57c00;
  border-color: #f57c00;
  color: white;
}

.content {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

.listsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 70px; /* Space for bottom nav */
}

.listCard {
  background-color: white;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.listCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.creatorInfo {
  display: flex;
  align-items: center;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #6a4c93;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.initials {
  color: white;
  font-weight: 700;
  font-size: 14px;
}

.creatorName {
  font-size: 14px;
  color: #333;
}

.saveButton {
  background: none;
  border: none;
  padding: 6px;
  cursor: pointer;
  color: #6a4c93;
}

.listTitle {
  margin: 0 0 8px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #333;
}

.listDescription {
  margin: 0 0 12px;
  font-size: 14px;
  color: #666;
}

.listStats {
  font-size: 12px;
  color: #888;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.savedCount {
  display: flex;
  align-items: center;
}

/* Trial badge styles - New */
.trialBadge {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #fff8e1;
  border-radius: 16px;
  padding: 4px 8px;
  color: #f57c00;
  font-weight: 600;
  font-size: 12px;
}

/* Theme colors */
.purpleTheme {
  border-left: 4px solid #f0e6fa;
}

.blueTheme {
  border-left: 4px solid #e6f5fa;
}

.pinkTheme {
  border-left: 4px solid #fae6f0;
}

.greenTheme {
  border-left: 4px solid #f5fae6;
}

.defaultTheme {
  border-left: 4px solid #eee;
}

.emptyState {
  text-align: center;
  padding: 32px 16px;
  color: #666;
}

.clearButton {
  background-color: transparent;
  border: 1px solid #6a4c93;
  color: #6a4c93;
  padding: 8px 16px;
  border-radius: 20px;
  margin-top: 12px;
  cursor: pointer;
  font-weight: 600;
}

.followingEmptyState {
  text-align: center;
  padding: 48px 16px;
  color: #666;
}

.exploreButton {
  background-color: #6a4c93;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(106, 76, 147, 0.3);
}

/* Bottom navigation */
.bottomNav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
  z-index: 10;
}

.navItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0;
  color: #888;
  font-size: 12px;
  cursor: pointer;
}

.navItem.active {
  color: #6a4c93;
}

.navIcon {
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
}