.friends-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
}

.header-section {
  margin-bottom: 40px;
}

.header-title {
  font-size: 36px !important;
  font-weight: bold !important;
  margin-bottom: 8px !important;
}

.subheader-title {
  font-size: 18px !important;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 24px !important;
}

.invite-friends-button {
  font-size: 16px;
  height: 40px;
  padding: 6px 24px;
}

.friends-container .ant-table-wrapper {
  margin-top: 24px;
}
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.friendsTableStyle {
  margin: 10%;
  border-radius: 10px;
  border: 1px solid grey;
}
