.ant-row {
  padding: none;
  margins: none;
}

.FriendRequestRow {
  display: flex;
  justify: center;
  align: middle;
}

.FriendRequestRow2 {
  display: flex;
  justify: center;
  align: top;
}
