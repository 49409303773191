.newNoteTextArea {
  width: 20px;
}

.userProductsContainer {
  margin: 50px;
  margin-right: 10px;
  margin-left: 100px;
  border-radius: 10px;
  padding: 2px;
  display: 'flex';
  flex-wrap:'wrap';
  background-color: 'white';
  justify-content:'center';
}

.ant-form-item {
  margin-bottom: 10px;
}


.formNotes {
  margin-left: 5%;
  margin-top: 2%;
  width: 90%;
  height: 500px;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: rgb(255, 254, 153);
}

/* .ant-input {
  background-color: rgb(255, 254, 210) !important;
} */

.newPostForm {
  margin-top: 2%;
  width: 75%;
  border-radius: 10px;
  outline: none;
  border: none !important;
  color: black;
  font-weight: 300;
  background-color: rgb(255, 254, 210) !important;
}

.loginForm {
  margin-top: 2%;
  width: 90%;
  border-radius: 10px;
  background-color: white;
}

.loginForm:hover {
  margin-top: 2%;
  width: 90%;
  border-radius: 10px;
  background-color: white;
}

.loginForm:focus {
  margin-top: 2%;
  width: 90%;
  border-radius: 10px;
  background-color: white;
}
