.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f5f7fa;
}

.header {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
}

.backButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 8px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  flex: 1;
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.titleEditContainer {
  flex: 1;
  display: flex;
  align-items: center;
}

.titleInput {
  flex: 1;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
}

.saveButton {
  margin-left: 8px;
  padding: 8px 16px;
  background-color: #6a4c93;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.headerButtons {
  display: flex;
  gap: 8px;
}

.shareButton, .checklistButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}

.checklistButton {
  color: #6a4c93;
}

.checklistIcon {
  color: #6a4c93;
}

.progressBar {
  position: relative;
  height: 15px;
  background-color: rgba(255, 255, 255, 0.4);
  overflow: hidden;
}

.progressFill {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  transition: width 0.3s ease;
}

.progressText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: bold;
  color: #444;
  mix-blend-mode: difference;
}

.searchContainer {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: white;
  border-bottom: 1px solid #eaeaea;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.searchInput {
  flex: 1;
  border: none;
  background: none;
  padding: 8px;
  font-size: 16px;
  outline: none;
}

.filterButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}

.activeFilter {
  color: #6a4c93;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 16px;
  text-align: center;
  color: #666;
}

.clearButton {
  margin-top: 16px;
  padding: 8px 16px;
  background-color: #f0e6fa;
  color: #6a4c93;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
}

.categoriesContainer {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
}

.categorySection {
  margin-bottom: 24px;
}

.categoryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  cursor: pointer;
}

.categoryTitle {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.itemsList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.itemCard {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.acquiredItem {
  opacity: 0.7;
}

.itemSwipeContainer {
  position: relative;
  overflow: hidden;
}

.itemSwipeActions {
  position: absolute;
  right: -180px;
  top: 0;
  bottom: 0;
  width: 180px;
  display: flex;
  transition: right 0.3s ease;
}

.swiping {
  right: 0;
}

.archiveButton, .deleteButton {
  flex: 1;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.archiveButton {
  background-color: #4a90e2;
}

.deleteButton {
  background-color: #e53e3e;
}

.itemContent {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background-color: white;
  cursor: pointer;
}

.itemDetails {
  flex: 1;
}

.itemName {
  margin: 0 0 4px 0;
  font-size: 16px;
  font-weight: bold;
}

.itemBrand {
  margin: 0 0 8px 0;
  font-size: 14px;
  color: #666;
}

/* New styles for item badges container */
.itemBadges {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 4px;
}

.priorityBadge {
  display: inline-flex;
  padding: 2px 8px;
  background-color: #f0e6fa;
  color: #6a4c93;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
}

/* New Trial Badge styles */
.trialBadge {
  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  background-color: #fff8e1;
  color: #f57c00;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  gap: 4px;
}

.trialIcon {
  width: 14px;
  height: 14px;
  stroke: #f57c00;
}

.itemActions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.itemMoveButtons {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.moveButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  font-size: 12px;
  color: #666;
}

.checkboxContainer {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.checkboxContainer input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  background-color: #eee;
  border-radius: 4px;
}

.checkboxContainer:hover .checkmark {
  background-color: #ccc;
}

.checkboxContainer input:checked ~ .checkmark {
  background-color: #6a4c93;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

.checkboxContainer .checkmark:after {
  left: 9px;
  top: 5px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.addButton {
  position: fixed;
  bottom: 24px;
  right: 24px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #6a4c93;
  color: white;
  border: none;
  border-radius: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-weight: bold;
  z-index: 100;
}

.addButton .icon {
  margin-right: 8px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.filterModal {
  width: 90%;
  max-width: 400px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #eaeaea;
}

.modalHeader h2 {
  margin: 0;
  font-size: 18px;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.filterSection {
  padding: 16px;
  border-bottom: 1px solid #eaeaea;
}

.filterSection h3 {
  margin: 0 0 12px 0;
  font-size: 16px;
}

.filterOptions {
  display: flex;
  gap: 8px;
}

.filterOption {
  flex: 1;
  padding: 8px 12px;
  background-color: #f5f7fa;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.filterOption.active {
  background-color: #f0e6fa;
  border-color: #6a4c93;
  color: #6a4c93;
}

/* Color styles for trial filter section */
.filterSection:nth-child(3) .filterOption.active {
  background-color: #fff8e1;
  border-color: #f57c00;
  color: #f57c00;
}

.filterActions {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.clearFiltersButton, .applyFiltersButton {
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}

.clearFiltersButton {
  background: none;
  border: 1px solid #eaeaea;
  color: #666;
}

.applyFiltersButton {
  background-color: #6a4c93;
  border: none;
  color: white;
}

/* Color theme classes */
.blueTheme {
  background-color: #e6f5fa;
}

.purpleTheme {
  background-color: #f0e6fa;
}

.pinkTheme {
  background-color: #fae6f0;
}

.greenTheme {
  background-color: #f5fae6;
}