.analytics-dashboard {
  padding: 20px;
  background-color: #fff;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
}

.headerTitleText {
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  width: 100%;
  color: black;
  background-color: white;
  padding-top: 15px;
  height: 50px;
  margin-bottom: 0;
  margin-top: 10px;
}

.subheaderTitleText {
  font-weight: 200;
  font-size: 20px;
  text-align: center;
  width: 100%;
  color: gray;
  margin-top: 5px;
  margin-bottom: 20px;
}

.progressContainer {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.progressText {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 500;
}

.progressBarOuter {
  width: 100%;
  height: 20px;
  background-color: #e8e8e8;
  border-radius: 10px;
  overflow: hidden;
}

.progressBarInner {
  height: 100%;
  background-color: #1890ff;
  border-radius: 10px;
  transition: width 0.5s ease-in-out;
}

.progressPercentage {
  font-size: 16px;
  color: #333;
  text-align: center;
  margin-top: 10px;
  font-weight: 500;
}

.scrollableContent {
  flex-grow: 1;
  padding: 20px;
  margin-bottom: 60px;
}

.dashboardGroup {
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
}

.groupTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

.analyticsCard {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: 100%;  /* Changed from auto to 100% */
}

.analyticsCard .ant-card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cardContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.analyticsCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.analyticsCard .ant-card-head {
  border-bottom: none;
}

.analyticsCard .ant-card-head-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.analyticsCard .ant-statistic-title {
  font-size: 16px;
  color: #666;
}

.analyticsCard .ant-statistic-content {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.analyticsCard .ant-timeline-item-content {
  font-size: 16px;
  color: #333;
}

.analyticsCard .ant-list-item-meta-title {
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

.analyticsCard .ant-list-item-meta-description {
  font-size: 16px;
  color: #666;
}

.dashboardIcon {
  color: #1890ff;
  font-size: 24px;
  background-color: none !important;
}

.actionButton {
  margin-top: auto;
}

/* Rotating background colors for group containers */
.dashboardGroup:nth-child(6n+1) { background-color: #f0f5ff; }
.dashboardGroup:nth-child(6n+2) { background-color: #f6ffed; }
.dashboardGroup:nth-child(6n+3) { background-color: #fff7e6; }
.dashboardGroup:nth-child(6n+4) { background-color: #f9f0ff; }
.dashboardGroup:nth-child(6n+5) { background-color: #e6fffb; }
.dashboardGroup:nth-child(6n) { background-color: #fff1f0; }

@media (max-width: 768px) {
  .analytics-dashboard {
    padding: 10px;
  }

  .headerTitleText {
    font-size: 20px;
    height: 40px;
  }

  .subheaderTitleText {
    font-size: 16px;
  }

  .analyticsCard {
    margin-bottom: 16px;
  }

  .analyticsCard .ant-card-head-title {
    font-size: 16px;
  }

  .analyticsCard .ant-statistic-title {
    font-size: 14px;
  }

  .analyticsCard .ant-statistic-content {
    font-size: 20px;
  }

  .groupTitle {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .headerTitleText {
    font-size: 18px;
    height: 35px;
  }

  .subheaderTitleText {
    font-size: 14px;
  }

  .analyticsCard .ant-card-head-title {
    font-size: 14px;
  }

  .analyticsCard .ant-statistic-title {
    font-size: 12px;
  }

  .analyticsCard .ant-statistic-content {
    font-size: 18px;
  }

  .groupTitle {
    font-size: 16px;
  }
}
