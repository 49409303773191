.container {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    min-height: 100vh;
    padding: 16px;
  }
  
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 50px;
  }
  
  .backButton {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #666666;
    padding: 8px;
    margin-right: 8px;
  }
  
  .title {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    margin: 0;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  
  .formGroup {
    margin-bottom: 24px;
  }
  
  .label {
    display: block;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
  }
  
  .input {
    width: 100%;
    padding: 12px 16px;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    border: 1px solid #dddddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    transition: border-color 0.2s;
  }
  
  .input:focus {
    outline: none;
    border-color: #6a4c93;
    box-shadow: 0 0 0 2px rgba(106, 76, 147, 0.1);
  }
  
  .errorText {
    color: #e53935;
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 0;
  }
  
  .templateGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  
  .templateCard {
    position: relative;
    height: 100px;
    border-radius: 12px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .templateCard:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .selectedTemplate {
    border: 2px solid #6a4c93;
    box-shadow: 0 4px 8px rgba(106, 76, 147, 0.2);
  }
  
  .templateName {
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #333333;
  }
  
  .checkmark {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    background-color: #6a4c93;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  
  .buttonContainer {
    margin-top: auto;
    display: flex;
    gap: 16px;
    margin-top: 24px;
  }
  
  .cancelButton {
    flex: 1;
    padding: 12px 16px;
    background-color: transparent;
    color: #6a4c93;
    border: 1px solid #6a4c93;
    border-radius: 25px;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .cancelButton:hover {
    background-color: rgba(106, 76, 147, 0.05);
  }
  
  .createButton {
    flex: 2;
    padding: 12px 16px;
    background-color: #6a4c93;
    color: white;
    border: none;
    border-radius: 25px;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .createButton:hover {
    background-color: #5a3d83;
  }
  
  /* Media queries for larger screens */
  @media (min-width: 768px) {
    .container {
      padding: 24px;
    }
  
    .templateGrid {
      grid-template-columns: repeat(3, 1fr);
    }
  }