:root {
  /* Logo and title colors - vibrant with glow */
  --color-deeper-blue: #4a68a6;
  --color-deeper-pink: #c15470;
  --color-deeper-sage: #648f6a;

  /* Glow colors for logo and title */
  --glow-blue: rgba(74, 104, 166, 0.4);
  --glow-pink: rgba(193, 84, 112, 0.4);
  --glow-sage: rgba(100, 143, 106, 0.4);

  /* Progress bar colors - soft baby colors */
  --progress-color-1: #b5d4ff;
  --progress-color-2: #ffc5d5;
  --progress-color-3: #b5d8b9;
  --progress-color-4: #fff4bd;
  --progress-color-5: #c8e0ff;
  --progress-color-6: #ffd6e3;
  --progress-color-7: #c5e4c9;
  --progress-color-8: #fff7cc;
  --progress-color-9: #9bc0eb;
  --progress-color-10: #ebaebb;
  --progress-color-11: #9fc8a3;
  --progress-color-12: #ebe4ad;

  /* Lighter variations (15% lighter with some transparency) */
  --color-blue-light: rgba(181, 212, 255, 0.85);
  --color-pink-light: rgba(255, 197, 213, 0.85);
  --color-sage-light: rgba(181, 216, 185, 0.85);
  --color-cream-light: rgba(255, 254, 210, 0.85);

  /* Dark variations for hover states */
  --color-deeper-blue-dark: #3b5385;
  --color-deeper-pink-dark: #9a4359;
  --color-deeper-sage-dark: #507254;

  /* Background colors */
  --background-primary: #ffffff;
  --background-secondary: var(--color-blue-light);
  --background-tertiary: var(--color-sage-light);
  --color-cream: rgb(255, 254, 210);
  --color-faded: #fafafa;

  /* Text colors */
  --text-primary: #333333;
  --text-secondary: #666666;
  --text-light: #ffffff;

  /* Status colors */
  --success: var(--progress-color-3);
  --warning: #ffd700;
  --error: #ff6b6b;
  --info: var(--progress-color-1);
}

.ant-layout {
  background-color: white;
  max-width: 100vw;
}

body {
  overflow-x: hidden !important;
  width: 100% !important;
}
