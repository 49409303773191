.ant-carousel .slick-slide > div {
  display: block !important;
}

.card-expanded-content {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;

}

.checklist-item-tabs {
  width: 100%;
}

.checklist-item-tabs .ant-tabs-nav {
  margin-left: 0;
}

.checklist-item-tab-content {
  width: 100%;
  box-sizing: border-box;
}

.shared-notes {
  width: 100%;
  margin-top: 0px;
  background: white;
  border: none;
  box-sizing: border-box;
}

.notes-menu {
  display: flex;
  justify-content: center;
  background-color: transparent;
  border-bottom: none;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 100%;
  border-radius: 10px;
  overflow-x: auto;
  padding: 0 5px;
}

.notes-menu .ant-menu-item {
  margin: 0;
  padding: 5px 15px;
  font-size: 14px;
  transition: all 0.3s ease;
  border-radius: 10px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.notes-menu .ant-menu-item::after {
  display: none;
}

.menu-item-mine {
  color: #333;
  background-color: rgba(254, 250, 188, 0.1);
}

.menu-item-friends {
  color: #333;
  background-color: rgba(179, 217, 255, 0.1);
}

.menu-item-others {
  color: #333;
  background-color: rgba(194, 240, 194, 0.1);
}

.notes-menu .ant-menu-item:hover,
.notes-menu .ant-menu-item-selected {
  font-weight: bold;
  color: #333 !important;
  border-bottom: none !important;
}

.notes-menu .ant-menu-item-selected.menu-item-mine,
.notes-menu .ant-menu-item.menu-item-mine:hover {
  background-color: #fefabc !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.notes-menu .ant-menu-item-selected.menu-item-friends,
.notes-menu .ant-menu-item.menu-item-friends:hover {
  background-color: #b3d9ff !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.notes-menu .ant-menu-item-selected.menu-item-others,
.notes-menu .ant-menu-item.menu-item-others:hover {
  background-color: #c2f0c2 !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.notes-menu .ant-menu-item:not(.ant-menu-item-selected):not(:hover) {
  color: #999 !important;
}

.carousel-container {
  width: 100%;
  position: relative;

  box-sizing: border-box;
  margin-top: 5px;
}

.carousel-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 200px;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer !important;
  font-size: 20px;
  color: #1890ff;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.carousel-arrow:hover {
  background-color: #1890ff;
  color: #fff;
}

.carousel-arrow.prev {
  left: 0;
}

.carousel-arrow.next {
  right: 0;
}

.note-input-container,
.saved-note,
.friend-note,
.others-note,
.invite-friends-container {
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  height: auto;
  min-height: 150px;
}

.note-input-container .notes-privacy-controls {
  margin-bottom: 10px;
}

.note-input-container .sticky-note-input {
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
}

.note-input-container .notes-actions {
  text-align: right;
}

.saved-note {
  background-color: #fefabc;
}

.saved-note p {
  margin-bottom: 5px;
}

.saved-note small {
  color: #888;
}

.friend-note {
  background-color: #e6f7ff;
}

.others-note {
  background-color: #f6ffed;
}

.friend-note .ant-list-item-meta-avatar,
.others-note .ant-list-item-meta-avatar {
  display: none;
}

.ant-list-item-meta-title {
  font-weight: bold;
}

.note-relevance {
  font-style: italic;
  font-size: 0.9em;
  margin-bottom: 5px;
}

.note-content {
  white-space: pre-wrap;
  text-align: left;
}

.invite-friends-container {
  text-align: center;
}

.invite-friends-container h4 {
  margin-bottom: 15px;
}

.invite-friends-input {
  margin-bottom: 10px;
  background: white !important;
  height: 32px !important;
  line-height: 32px !important;
  border: 1px solid #e8e8e8 !important;
  border-radius: 8px !important;
  min-height: 0px !important;
  width: 100%;
  box-sizing: border-box;
}

.invite-friends-button {
  width: 100%;
}

.analyticsCard.moment-card.task .ant-card-head-title {
  text-align: left;
}

.ant-carousel .slick-slide {
  text-align: center;
  background: #fff;
  padding: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  box-sizing: border-box;
}

.ant-list-item {
  border-bottom: none;
}

.ant-carousel {
  width: 100%;
}

@media (max-width: 768px) {
  .card-expanded-content {

  }

  .carousel-container {

  }

  .notes-menu .ant-menu-item {
    margin: 0 3px;
    padding: 0 3px;
    font-size: 13px;
  }

  .note-input-container,
  .saved-note,
  .friend-note,
  .others-note,
  .invite-friends-container {
    padding: 12px;
  }

  .carousel-slide {
    min-height: 180px;
  }
}

@media (max-width: 480px) {
  .card-expanded-content {

  }

  .carousel-container {

  }

  .notes-menu .ant-menu-item {
    margin: 0 2px;
    padding: 0 2px;
    font-size: 12px;
  }

  .note-input-container,
  .saved-note,
  .friend-note,
  .others-note,
  .invite-friends-container {
    padding: 10px;
  }

  .carousel-slide {
    min-height: 150px;
  }

  .carousel-arrow {
    width: 24px;
    height: 24px;
    font-size: 16px;
  }

  .invite-friends-input {
    height: 28px !important;
    line-height: 28px !important;
  }

  .note-input-container .sticky-note-input {
    font-size: 12px;
  }
}
