.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff;
  padding-bottom: 24px;
}

.header {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 10;
  margin-top: 50px;
}

.backButton {
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  padding: 8px;
  margin-right: 8px;
}

.title {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  flex-grow: 1;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 20px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #333;
}

.required {
  color: #e74c3c;
  margin-left: 4px;
}

.input, .select, .textarea {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.input:focus, .select:focus, .textarea:focus {
  outline: none;
  border-color: #6a4c93;
  box-shadow: 0 0 0 2px rgba(106, 76, 147, 0.2);
}

.inputError {
  border-color: #e74c3c;
}

.errorText {
  color: #e74c3c;
  font-size: 14px;
  margin: 4px 0 0;
}

.selectContainer {
  position: relative;
}

.select {
  width: 100%;
  appearance: none;
  padding-right: 32px;
  cursor: pointer;
}

.selectContainer::after {
  content: '';
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #666;
  pointer-events: none;
}

.toggleContainer {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #ddd;
}

.toggleButton {
  flex: 1;
  padding: 12px;
  border: none;
  background-color: #f9f9f9;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggleActive {
  background-color: #6a4c93;
  color: white;
}

.radioGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.radioLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radioInput {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.radioText {
  padding: 8px 16px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 14px;
  transition: all 0.3s;
}

.radioInput:checked + .radioText {
  background-color: #6a4c93;
  color: white;
  border-color: #6a4c93;
}

.textarea {
  resize: vertical;
  min-height: 100px;
}

/* Trial availability checkbox container - New */
.checkboxContainer {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

/* Custom checkbox for trial availability - New */
.checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  transition: all 0.2s;
}

.checkbox:checked {
  background-color: #f57c00;
  border-color: #f57c00;
}

.checkbox:checked::after {
  content: '';
  position: absolute;
  top: 4px;
  left: 7px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkboxText {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  color: #333;
}

/* Sub form group for trial details - New */
.subFormGroup {
  margin-top: 12px;
  margin-left: 16px;
  padding-left: 14px;
  border-left: 2px solid #f57c00;
}

/* Trial details textarea styling - New */
.subFormGroup .textarea {
  background-color: #fff8e1;
  border-color: #ffcc80;
}

.subFormGroup .textarea:focus {
  border-color: #f57c00;
  box-shadow: 0 0 0 2px rgba(245, 124, 0, 0.2);
}

.actionButtons {
  display: flex;
  gap: 12px;
  margin-top: 16px;
}

.cancelButton, .saveButton {
  flex: 1;
  padding: 14px;
  border-radius: 25px;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}

.cancelButton {
  background-color: transparent;
  border: 1px solid #6a4c93;
  color: #6a4c93;
}

.saveButton {
  background-color: #6a4c93;
  border: none;
  color: white;
}

.cancelButton:hover {
  background-color: rgba(106, 76, 147, 0.1);
}

.saveButton:hover {
  background-color: #5a3f7d;
}

.cancelButton:active, .saveButton:active {
  transform: scale(0.98);
}