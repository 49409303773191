.perksContainer {
  margin: 20px 0;
  padding: 15px;
  background-color: #f8f5ff;
  border-radius: 12px;
}

.perksTitle {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}

.perksDescription {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
}

.perksScroller {
  display: flex;
  overflow-x: auto;
  gap: 12px;
  padding-bottom: 10px;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}

.perksScroller::-webkit-scrollbar {
  height: 4px;
}

.perksScroller::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 10px;
}

.perksScroller::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

.perkCard {
  min-width: 200px;
  flex: 0 0 auto;
  background: white;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.perkCard h3 {
  font-size: 16px;
  margin: 0 0 8px 0;
  padding-right: 40px;
}

.perkCard p {
  font-size: 14px;
  color: #666;
  margin: 0 0 10px 0;
}

.perkBadge {
  position: absolute;
  top: -8px;
  right: 10px;
  background: #6a4c93;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 3px 8px;
  border-radius: 12px;
}

.claimButton {
  background-color: #6a4c93;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  cursor: pointer;
}

.claimButton:hover {
  background-color: #5a3b83;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f5f7fa;
  font-family: 'Nunito', sans-serif;
}

.header {
  padding: 20px 16px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  z-index: 10;
  margin-top: 50px;
}

.title {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #333333;
}

.content {
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.createButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #6a4c93;
  color: white;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 16px;
  padding: 14px 24px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(106, 76, 147, 0.2);
  transition: all 0.2s ease;
}

.createButton:hover {
  background-color: #5a3a83;
  box-shadow: 0 6px 8px rgba(106, 76, 147, 0.3);
}

.plusIcon {
  font-size: 20px;
  font-weight: 700;
}

.listsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.listCard {
  background-color: white;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.listCard::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 100%;
  background-color: #dddddd;
}

.listCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.purpleTheme::before {
  background-color: #f0e6fa;
}

.blueTheme::before {
  background-color: #e6f5fa;
}

.pinkTheme::before {
  background-color: #fae6f0;
}

.greenTheme::before {
  background-color: #f5fae6;
}

.orangeTheme::before {
  background-color: #faf0e6;
}

.listTitle {
  margin: 0 0 8px 0;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #333333;
}

.listDetails {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.listType {
  text-transform: capitalize;
  font-weight: 500;
  color: #666666;
}

.listItems {
  font-weight: 500;
  color: #666666;
}

/* Trial badge styles - New */
.trialBadge {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #fff8e1;
  color: #f57c00;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
  width: fit-content;
}

.trialIcon {
  width: 16px;
  height: 16px;
  stroke: #f57c00;
}

.listDate {
  font-size: 12px;
  color: #999999;
}

.bottomNav {
  display: flex;
  justify-content: space-around;
  background-color: white;
  padding: 12px 0 8px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.05);
}

.navItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666666;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 0;
  cursor: pointer;
}

.navIcon {
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
}

.navItem.active {
  color: #6a4c93;
  font-weight: 700;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .listsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}

@media (min-width: 1024px) {
  .listsContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}