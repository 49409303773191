.invite-friends-button {
  background-color: #1890ff;
  border-color: #1890ff;
  color: #fff;
}

.invite-friends-button:hover,
.invite-friends-button:focus {
  background-color: #40a9ff;
  border-color: #40a9ff;
  color: #fff;
}

.ant-select-selection-placeholder {
  text-align: center;
}

.ant-select-selection-item {
  text-align: center;
}

.ant-form-item-label > label {
  justify-content: center;
}

.invite-friends-input {
  text-align: center;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .ant-modal-header .ant-modal{
    background: white;
    border: none;
    border-radius: 10px;
  }

   .headerTextStyle {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 0px;
      margin-top: 10px;
   }

   .subHeaderTextStyle {
      text-align: 'center';
      font-size: 18px;
  }

  .inviteFriendsIconStyle{
      width: 70%;
  }

  .bodyTextStyle1{
      font-size: 20px;
      margin-top: 20px;
      display: inline-block;
      text-align: left;
  }

  .bodyTextStyle{
      font-size: 16px;
      margin-top: 20px;
      text-align: center;
  }

  .ant-divider-horizontal{
    margin: 0px;
    margin-left: 35%;
    width: 30%;
    min-width: 20%;
  }

  .ant-modal{
    min-width: 50%;
    max-width: 90%;
  }

  .ant-modal-body {
    background: white;
    border: none;
    border-radius: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .ant-modal-footer{
    border: none;
  }

  .ant-modal-content{
    background: white;
    box-shadow: none;
    border-radius: 10px;
  }

  .enterEmailInput button {
    background-color: #ffff14;
    border-color: #ffff14;
    color: black;
    font-size: 16px;
    border-color: grey;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .ant-input-affix-wrapper{
    border-radius: 10px;
    border-color: grey;
  }

  .ant-input-group-addon{
    border-radius: 20px;
  }

  .enterEmailInput button:hover{
    background-color: #ffff14;
    border-color: #ffff14;
    font-weight: bold;
    border-color: grey;
    color: black;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .enterEmailInput button:focus{
    background-color: grey;
    color: white;
    font-weight: normal;
    border-color: #7E3FFF;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .enterEmailInput .ant-form-item-children-icon{
    margin-right: 80px;
  }

  .ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split{
    color: white;
  }

  .ant-input-group {
    width: 310px;
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .ant-modal-header .ant-modal{
    background: white;
    border: none;
    border-radius: 10px;
  }

   .headerTextStyle {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 0px;
      margin-top: 10px;
   }

   .subHeaderTextStyle {
      text-align: 'center';
      font-size: 18px;
  }

  .inviteFriendsIconStyle{
      width: 70%;
  }

  .bodyTextStyle1{
      font-size: 20px;
      margin-top: 20px;
      display: inline-block;
      text-align: left;
  }

  .bodyTextStyle{
      font-size: 16px;
      margin-top: 20px;
      text-align: center;
  }

  .ant-divider-horizontal{
    margin: 0px;
    margin-left: 35%;
    width: 30%;
    min-width: 20%;
  }

  .ant-modal{
    min-width: 70%;
    max-width: 90%
  }

  .ant-modal-body {
    background: white;
    border: none;
    border-radius: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .ant-modal-footer{
    border: none;
  }

  .ant-modal-content{
    background: white;
    box-shadow: none;
    border-radius: 10px;
  }

  .enterEmailInput button {
    background-color: #ffff14;
    border-color: #ffff14;
    color: black;
    font-size: 16px;
    border-color: grey;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .ant-input-affix-wrapper{
    border-radius: 10px;
    border-color: grey;
  }

  .ant-input-group-addon{
    border-radius: 20px;
  }

  .enterEmailInput button:hover{
    background-color: #ffff14;
    border-color: #ffff14;
    font-weight: bold;
    border-color: grey;
    color: black;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .enterEmailInput button:focus{
    background-color: grey;
    color: white;
    font-weight: normal;
    border-color: #7E3FFF;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .enterEmailInput .ant-form-item-children-icon{
    margin-right: 80px;
  }

  .ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split{
    color: white;
  }

  .ant-input-group {
    width: 310px;
  }

}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .ant-modal-header .ant-modal{
    background: white;
    border: none;
    border-radius: 10px;
  }

   .headerTextStyle {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 0px;
      margin-top: 10px;
   }

   .subHeaderTextStyle {
      text-align: 'center';
      font-size: 18px;
  }

  .inviteFriendsIconStyle{
      width: 60%;
  }

  .bodyTextStyle1{
      font-size: 20px;
      margin-top: 20px;
      display: inline-block;
      text-align: left;
  }

  .bodyTextStyle{
      font-size: 18px;
      margin-top: 20px;
      text-align: center;
  }

  .ant-divider-horizontal{
    margin: 0px;
    margin-left: 35%;
    width: 30%;
    min-width: 20%;
  }

  .ant-modal{
    min-width: 80%;
    max-width: 90%
  }

  .ant-modal-body {
    background: white;
    border: none;
    border-radius: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .ant-modal-footer{
    border: none;
  }

  .ant-modal-content{
    background: white;
    box-shadow: none;
    border-radius: 10px;
  }

  .enterEmailInput button {
    background-color: #ffff14;
    border-color: #ffff14;
    color: black;
    font-size: 16px;
    border-color: grey;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .ant-input-affix-wrapper{
    border-radius: 10px;
    border-color: grey;
  }

  .ant-input-group-addon{
    border-radius: 20px;
  }

  .enterEmailInput button:hover{
    background-color: #ffff14;
    border-color: #ffff14;
    font-weight: bold;
    border-color: grey;
    color: black;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .enterEmailInput button:focus{
    background-color: grey;
    color: white;
    font-weight: normal;
    border-color: #7E3FFF;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .enterEmailInput .ant-form-item-children-icon{
    margin-right: 80px;
  }

  .ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split{
    color: white;
  }

  .ant-input-group {
    width: 310px;
  }

}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .ant-modal-header .ant-modal{
    background: white;
    border: none;
    border-radius: 10px;
  }

   .headerTextStyle {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 0px;
      margin-top: 10px;
   }

   .subHeaderTextStyle {
      text-align: 'center';
      font-size: 18px;
  }

  .inviteFriendsIconStyle{
      width: 70%;
  }

  .bodyTextStyle1{
      font-size: 20px;
      margin-top: 20px;
      display: inline-block;
      text-align: left;
  }

  .bodyTextStyle{
      font-size: 18px;
      margin-top: 20px;
      text-align: center;
  }

  .ant-divider-horizontal{
    margin: 0px;
    margin-left: 35%;
    width: 30%;
    min-width: 20%;
  }

  .ant-modal{
    min-width: 80%;
    max-width: 90%
  }

  .ant-modal-body {
    background: white;
    border: none;
    border-radius: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .ant-modal-footer{
    border: none;
  }

  .ant-modal-content{
    background: white;
    box-shadow: none;
    border-radius: 10px;
  }

  .enterEmailInput button {
    background-color: #ffff14;
    border-color: #ffff14;
    color: black;
    font-size: 16px;
    border-color: grey;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .ant-input-affix-wrapper{
    border-radius: 10px;
    border-color: grey;
  }

  .ant-input-group-addon{
    border-radius: 20px;
  }

  .enterEmailInput button:hover{
    background-color: #ffff14;
    border-color: #ffff14;
    font-weight: bold;
    border-color: grey;
    color: black;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .enterEmailInput button:focus{
    background-color: grey;
    color: white;
    font-weight: normal;
    border-color: #7E3FFF;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .enterEmailInput .ant-form-item-children-icon{
    margin-right: 80px;
  }

  .ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split{
    color: white;
  }

  .ant-input-group {
    width: 310px;
  }


}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .ant-modal-header .ant-modal{
    background: white;
    border: none;
    border-radius: 10px;
  }

   .headerTextStyle {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 0px;
      margin-top: 10px;
   }

   .subHeaderTextStyle {
      text-align: 'center';
      font-size: 18px;
  }

  .inviteFriendsIconStyle{
      width: 60%;
  }

  .bodyTextStyle1{
      font-size: 20px;
      margin-top: 20px;
      display: inline-block;
      text-align: left;
  }

  .bodyTextStyle{
      font-size: 18px;
      margin-top: 20px;
      text-align: center;
  }

  .ant-divider-horizontal{
    margin: 0px;
    margin-left: 40%;
    width: 10%;
    min-width: 20%;
  }

  .ant-modal{
    min-width: 60%;
    max-width: 80%
  }

  .ant-modal-body {
    background: white;
    border: none;
    border-radius: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .ant-modal-footer{
    border: none;
  }

  .ant-modal-content{
    background: white;
    box-shadow: none;
    border-radius: 10px;
  }

  .enterEmailInput button {
    background-color: #ffff14;
    border-color: #ffff14;
    color: black;
    font-size: 16px;
    border-color: grey;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .ant-input-affix-wrapper{
    border-radius: 10px;
    border-color: grey;
  }

  .ant-input-group-addon{
    border-radius: 20px;
  }

  .enterEmailInput button:hover{
    background-color: #ffff14;
    border-color: #ffff14;
    font-weight: bold;
    border-color: grey;
    color: black;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .enterEmailInput button:focus{
    background-color: grey;
    color: white;
    font-weight: normal;
    border-color: #7E3FFF;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .enterEmailInput .ant-form-item-children-icon{
    margin-right: 80px;
  }

  .ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split{
    color: white;
  }

  .ant-input-group {
    width: 310px;
  }

}
