margin-left: 25px;/* must remove the ant.btn from Login.css becuase it's creating a conflict
and causing issues like border radius changes and stuff, higher in the hierarchy l */
.ant-layout-header {
}
.loginForm {
  width: 50vw;
  background-color: white;
}
.bodyContainerA {
  display: flex;             /* Enable Flexbox */
  justify-content: space-between;  /* Distribute items evenly */
  align-items: center;       /* Align items vertically in the center */
  flex-wrap: wrap;           /* Wrap items to the next row if necessary */
}
.bodyContainerB {
  display: flex;             /* Enable Flexbox */
  justify-content: space-between;  /* Distribute items evenly */
  align-items: center;       /* Align items vertically in the center */
  flex-wrap: wrap;           /* Wrap items to the next row if necessary */
  height: auto;
  position: sticky;
  top: 100px;
}

.inviteFriendsContainer {
  text-align: center;
}

.bodyContainerC {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 0px;
}
.bodyRowContainer1 {
  height: auto;
}
.ChecklistContainer {
  height: auto;
  padding: 5px;
  text-align: center !important;
}

.bodyRowContainer {
  margin: 5px;
}

.contentContainer{
  font-size: 24px;
  padding: 5px;
  width: 60vw;
  font-size: 20px;
  color: gray;
  position: relative;

}
.imageContainer {
  width: 40vw;
}

.selectCategoryPhoto {
  width: 100vw;
  position: sticky;
  top: 75px;
}

.stickyProductTextContainer {
  background-color: white;
  top: 50px;
  z-index: 2;
  position: sticky;
}

.stickyProductSubTextContainer1a, .stickyProductSubTextContainer2a, .stickyProductSubTextContainer3a, .stickyProductSubTextContainer1b, .stickyProductSubTextContainer2b, .stickyProductSubTextContainer3b, .stickyHeaderContainerA, .stickyHeaderContainerB, .newWayToResearchClosingSection {
  text-align: center; /* This will center the text inside horizontally */
  display: flex; /* Start of styles for vertical centering of text */
  justify-content: center; /* Center content horizontally in case it's not a block-level child */
  text-align: center; /* This will center the text inside horizontally */
  margin: 0 auto;
  position: sticky;
}

.stickyProductSubTextContainer1, .stickyProductSubTextContainer2, .stickyProductSubTextContainer3,{
  align-items: center; /* Center content vertically */
}

.stickyProductSubTextContainer1a {
  font-size: 24px;
  padding: 5px;
  font-size: 34px;
  color: black;
  width: 60vw;
  top: 120px;
  margin-bottom: 407px;
}
.stickyProductSubTextContainer2a {
  font-size: 24px;
  padding: 5px;
  font-size: 40px;
  color: black;
  width: 70vw;
  position: sticky;
  top: 160px;
  margin-bottom: 370px;
  margin-left: 15vw;
  text-shadow: none;
}
.stickyProductSubTextContainer3a {
  font-size: 24px;
  padding: 5px;
  font-size: 60px;
  color: black;
  width: 80vw;
  position: sticky;
  top: 220px;
  margin-bottom: 300px;
  margin-left: 10vw;
  text-shadow: none;
}

.stickyProductSubTextContainer1b {
  font-size: 24px;
  padding: 5px;
  font-size: 34px;
  color: black;
  width: 60vw;
  top: 120px;
  margin-bottom: 300px;
  align-items: center;
}
.stickyProductSubTextContainer2b {
  font-size: 24px;
  padding: 5px;
  font-size: 40px;
  color: black;
  width: 70vw;
  position: sticky;
  top: 220px;
  margin-bottom: 200px;
  margin-left: 15vw;
  text-shadow: none;
  align-items: center;
}
.stickyProductSubTextContainer3b {
  font-size: 24px;
  padding: 5px;
  font-size: 60px;
  color: black;
  width: 80vw;
  position: sticky;
  top: 320px;
  margin-bottom: 100px;
  margin-left: 10vw;
  text-shadow: none;
  align-items: center;
}

.designedForCollaborationPoint{
  display: flex;
  align-items: center;
}
.designedForCollaborationPoint1, .designedForCollaborationPoint2, .designedForCollaborationPoint3 {
  position: sticky;
  margin-bottom: 100px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;  /* Stack children vertically */
  align-items: center;  /* Center children horizontally */
  width: 33vw;
}
.designedForCollaborationPointDivider {
  height: 200px;
  z-index: 0;
  position: absolute;
}
.designedForCollaborationPointNumber{
  font-size: 160px;
  font-weight: 600;
  opacity: .1;
  margin-top: 0px;
  margin-bottom: 0px;
}
.designedForCollaborationSubText1, .designedForCollaborationSubText2, .designedForCollaborationSubText3 {
  font-size: 18px;
}

.designedForCollaborationSubText {
  font-size: 24px;
}

.stickyProductSubTextContainer1c {
  font-size: 24px;
  padding: 5px;
  font-size: 24px;
  color: black;
  width: 100vw;
  position: sticky;
  top: 130px;
  margin-bottom: 200px;
  margin-left: 5vw;
  text-shadow: none;
}
.stickyProductSubTextContainer2c {
  font-size: 24px;
  padding: 5px;
  font-size: 24px;
  color: black;
  width: 60vw;
  position: sticky;
  top: 180px;
  margin-bottom: 200px;
  margin-left: 8vw;
  text-shadow: none;
}
.stickyProductSubTextContainer3c {
  font-size: 24px;
  padding: 5px;
  font-size: 24px;
  color: black;
  width: 60vw;
  position: sticky;
  top: 230px;
  margin-bottom: 200px;
  margin-left: 12vw;
  text-shadow: none;
}
.stickyProductSubTextContainer4c {
  font-size: 24px;
  padding: 5px;
  font-size: 24px;
  color: black;
  width: 60vw;
  position: sticky;
  top: 280px;
  margin-bottom: 200px;
  margin-left: 15vw;
  text-shadow: none;
}
.stickyProductSubTextContainer5c {
  font-size: 24px;
  padding: 5px;
  font-size: 24px;
  color: black;
  width: 60vw;
  position: sticky;
  top: 330px;
  margin-bottom: 200px;
  margin-left: 18vw;
  text-shadow: none;
}
.stickyProductSubTextContainer6c {
  font-size: 24px;
  padding: 5px;
  font-size: 24px;
  color: black;
  width: 60vw;
  position: sticky;
  top: 380px;
  margin-bottom: 200px;
  margin-left: 21vw;
  text-shadow: none;
}
.stickyProductSubTextContainer7c {
  font-size: 24px;
  padding: 5px;
  font-size: 24px;
  color: black;
  width: 60vw;
  position: sticky;
  top: 430px;
  margin-bottom: 200px;
  margin-left: 24vw;
  text-shadow: none;
}
.stickyProductSubTextContainer8c {
  font-size: 24px;
  padding: 5px;
  font-size: 24px;
  color: black;
  width: 60vw;
  position: sticky;
  top: 480px;
  margin-bottom: 200px;
  margin-left: 27vw;
  text-shadow: none;
}
.newWayToResearchClosingSection {
  font-size: 24px;
  padding: 5px;
  font-size: 30px;
  color: black;
  width: 90vw;
  top: 100px;
  margin-bottom: 200px;
  box-shadow: 2px 2px 2px 2px #7E3FFF;
  background-color: white;
  z-index: 2;
}

.stickyProductSubTextContainer9d {
  font-size: 24px;
  padding: 5px;
  font-size: 30px;
  color: black;
  width: 10vw;
  top: 300px;
  margin-left: 33vw;
  width: 33vw;
}

.stickyHeaderContainerA {
  font-size: 24px;
  padding: 5px;
  font-size: 24px;
  color: black;
  background-color: white;
  width: 250px;
  position: sticky;
  height: 235px;
  top: 75px;
  text-align: center;
  margin-bottom: 90px;
  margin-left: 75px;
  margin-top: 50px;
  z-index: 2;
}
.stickyHeaderContainerBlank{
  font-size: 24px;
  padding: 5px;
  font-size: 24px;
  color: black;
  background-color: white;
  width: 250px;
  position: sticky;
  height: 235px;
  top: 0px;
  text-align: center;
  margin-bottom: 90px;
  margin-left: 75px;
  z-index: 1;
}

.stickyHeaderContainerB {
  font-size: 24px;
  padding: 5px;
  background-color: white;
  width: 90vw;
  position: sticky;
  height: auto;
  top: 75px;
  text-align: center;
  margin-bottom: 90px;
}

.designedForCollaborationText {
  margin-bottom: 50px;
  width: 100vw;
}

.stickyHeaderContainerC {
  font-size: 24px;
  padding: 5px;
  font-size: 24px;
  color: black;
  background-color: white;
  width: 100vw;
  position: sticky;
  height: 600px;
  top: 300px;
  text-align: center;
}
.stickyHeaderContainerA {
  box-shadow: 2px 2px 2px 2px #7E3FFF;
}

.designedForCollaborationImage {
  width: 100vw;
  position: sticky;
  top: 75px;
  z-index: 1;
}

.stickyProductTextContainer2 {
  font-size: 24px;
  padding: 5px;
  font-size: 20px;
  color: gray;
  width: 50vw;
  position: sticky;
  top: 125px;
  margin-bottom: 240px;
  margin-left: 25vw;
  z-index: 3;
}

.stickyProductTextContainer3 {
  font-size: 24px;
  padding: 5px;
  font-size: 20px;
  color: gray;
  width: 50vw;
  position: sticky;
  top: 155px;
  margin-bottom: 210px;
  margin-left: 25vw;
  z-index: 3;
}

.stickyProductTextContainer4 {
  font-size: 24px;
  padding: 5px;
  font-size: 20px;
  color: gray;
  width: 50vw;
  position: sticky;
  top: 185px;
  margin-bottom: 180px;
  margin-left: 25vw;
  z-index: 3;
}

.stickyProductTextContainer5 {
  font-size: 24px;
  padding: 5px;
  font-size: 20px;
  color: gray;
  width: 50vw;
  position: sticky;
  top: 215px;
  margin-bottom: 150px;
  margin-left: 25vw;
  z-index: 3;
}

.stickyProductTextContainer6 {
  font-size: 24px;
  padding: 5px;
  font-size: 20px;
  color: gray;
  width: 50vw;
  position: sticky;
  top: 245px;
  margin-bottom: 120px;
  margin-left: 25vw;
  z-index: 3;
}

.stickyProductTextContainer7, .stickyProductTextContainer17 {
  background-color: white;
  font-size: 24px;
  padding: 5px;
  font-size: 34px;
  color: black;
  width: 50vw;
  position: sticky;
  box-shadow: 2px 2px 2px 2px #7E3FFF;
}

.stickyProductTextContainer8 {
  background-color: white;
  font-size: 24px;
  padding: 5px;
  font-size: 20px;
  color: gray;
  width: 50vw;
  top: 275px;
  text-align: center;
  margin-bottom: 100px;
}

.stickyProductTextContainer10 {
  font-size: 24px;
  padding: 5px;
  width: 60vw;
  font-size: 20px;
  color: gray;
  width: 50vw;
  position: sticky;
  top: 75px;
  text-align: center;

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .heroTextContainer{
    width: 100vw;
    text-align: center;
  }
  .heroText {
    font-size: 24px;
    margin-top: 35px;
    margin-left: 5vw;
    margin-right: 5vw;
    text-align: center;
    margin-bottom: 10px;
    line-height: 30px;
  }
  .heroSubText1  {
    font-size: 20px;
    color: black;
    margin-left: 5vw;
    margin-right: 5vw;
    text-align: center;
  }
  .heroSubText2  {
    font-size: 20px;
    color: gray;
    margin-left: 5vw;
    margin-right: 5vw;
    text-align: center;
  }
  .heroPhoto {
    width: 60vw;
    margin-top: -25px;
  }
  .bannerContainer {
    height: 50px;
    max-width: 100vw;
    background-color: #7E3FFF;
  }
  .categoryImage {
    width: 50vw;
    margin-left: 25vw;
    z-index: 1;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .heroTextContainer{
    width: 100vw;
    text-align: center;
  }
  .heroText{
    font-size: 35px;
    margin-top: 35px;
    margin-left: 5vw;
    margin-right: 5vw;
    text-align: center;
    margin-bottom: 10px;
    line-height: 40px;
  }
  .heroSubText1{
    font-size: 20px;
    color: black;
    margin-left: 10vw;
    margin-right: 10vw;
    text-align: center;
    margin-bottom: 0px;
  }
  .heroSubText2{
    font-size: 20px;
    color: gray;
    margin-left: 10vw;
    margin-right: 10vw;
    text-align: center;
    margin-bottom: 0px;
  }
  .heroPhoto {
    width: 50vw;
    margin-right: 10vw;
    margin-left: 10vw;
    margin-top: 15px;
  }
  .bannerContainer {
    height: 50px;
    background-color: #7E3FFF;
    max-width: 100vw;
  }

  .siderContainerLeft, .siderContainerRight {
    width: 100vw;
  }

  .contentContainerLeft, .contentContainerRight {
    width: 100vw;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .heroTextContainer{
    width: 100vw;
  }
  .heroText{
    font-size: 38px;
    margin-top: 35px;
    margin-left: 50px;
    text-align: left;
    line-height: 40px;
  }
  .heroSubText1{
    font-size: 20px;
    color: black;
    margin-left: 50px;
    text-align: left;
    margin-bottom: 0px;
  }
  .heroSubText2{
    font-size: 20px;
    color: gray;
    margin-left: 50px;
    text-align: left;
    margin-bottom: 0px;
  }
  .heroPhoto {
    width: 80vw;
    margin-right: 10vw;
    margin-left: 10vw;
  }
  .bannerContainer {
    height: 50px;
    background-color: #7E3FFF;
    max-width: 100vw;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .heroText{
    font-size: 40px;
    margin-top: 35px;
    margin-left: 100px;
    margin-right: 10vw;
    text-align: left;
    margin-bottom: 10px;
    line-height: 40px;
  }
  .heroSubText1{
    font-size: 20px;
    color: black;
    margin-left: 100px;
    text-align: left;
    margin-bottom: 0px;
  }
  .heroSubText2{
    font-size: 20px;
    color: gray;
    margin-left: 100px;
    text-align: left;
    margin-bottom: 0px;
  }
  .heroPhoto {
    width: 80vw;
    margin-right: 10vw;
    margin-left: 10vw;
  }
  .bannerContainer {
    height: 50px;
    background-color: #7E3FFF;
    max-width: 100vw;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .heroText{
    font-size: 40px;
    margin-top: 35px;
    text-align: left;
    margin-bottom: 10px;
    top: 0px;
    margin-left: 100px;
    line-height: 40px;
  }
  .{
    font-size: 20px;
    color: black;
    text-align: left;
    margin-left: 100px;
    margin-bottom: 0px;
  }
  .heroSubText2{
    font-size: 20px;
    color: gray;
    text-align: left;
    margin-left: 100px;
    margin-bottom: 0px;
  }
  .heroPhoto {
    width: 80vw;
    margin-left: 10vw;
    margin-right: 80vw;
  }
  .bannerContainer {
    height: 50px;
    background-color: #7E3FFF;
    text-align: center;
  }
  .bannerText {
    font-size: 20px;
    color: white;
  }
}
