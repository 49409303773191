.ant-layout-header{
  background-color: white;
  box-shadow: 1px 1px 6px #9E9E9E;
  color: black;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub{
  box-shadow: none;
  background-color: white;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover{
  color: #7E3FFF;
  border-bottom: 2px solid white;
}

.ant-menu-item-selected{
  border-bottom: 2px solid white;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{
  color: #7E3FFF;
  font-weight: bolder;
  border-bottom: 2px solid white;
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a{
  color: black;
}

.ant-menu .ant-menu-light .ant-menu-root .ant-menu-horizontal{
  width: 90%;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover{
  background-color: white;
  color: #7E3FFF;
}
